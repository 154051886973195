import { Tabs, TabsProps } from 'antd';
import './styles.less';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { ListDocumentsTrash } from './trash';
import { ListDocuments } from './documents';
import { CreateDocumentButton } from '../create-document-button';
import { useState } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';
import { CINDER_URLS } from 'utils/cinder-urls';
import goToCinder from 'utils/goToCinder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export function PipelineTabs() {
  const tabsKeys = {
    DOCUMENTS: 'documents',
    TRASH: 'trash',
    ARCHIVES: 'archives',
  };

  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(tabsKeys.DOCUMENTS);
  const featureFlags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];
  const isDocumentsV3Enabled = featureFlags[FeatureFlags.documentsV3];

  const itemForTrash: TabsProps['items'] = [
    {
      key: tabsKeys.TRASH,
      label: (
        <>
          {<Icon name="IcoTrash" />}
          {t('pipeline.tabs.trash_document_list.title')}
        </>
      ),
      children: <ListDocumentsTrash />,
    },
  ];
  const itemForArchives: TabsProps['items'] = [
    {
      key: tabsKeys.ARCHIVES,
      label: (
        <>
          {<Icon name="IcoArchives" />}
          {t('pipeline.tabs.archives.title')}
        </>
      ),
      children: <></>,
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: tabsKeys.DOCUMENTS,
      label: (
        <>
          <span>{<InsertDriveFileOutlinedIcon />}</span>
          {t('pipeline.tabs.document_list.title')}
        </>
      ),
      children: <ListDocuments />,
    },
    ...(!isDocumentsV3Enabled ? [] : itemForArchives),
    ...(isEditorV3Enabled ? itemForTrash : []),
  ];

  return (
    <div className="pipeline-tabs">
      <Tabs
        tabPosition={'left'}
        items={items}
        destroyInactiveTabPane={true}
        activeKey={currentTab}
        onChange={(activeTab) => {
          if (activeTab === tabsKeys.ARCHIVES) {
            goToCinder(CINDER_URLS.archives);
            return;
          }
          setCurrentTab(activeTab);
        }}
        tabBarExtraContent={{ left: currentTab !== tabsKeys.TRASH ? <CreateDocumentButton /> : null }} //left works as 'top' since we are using tabPosition left
      />
    </div>
  );
}
