import * as React from 'react';
const SvgIcoTableColumnAddAfter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 2a2 2 0 012 2v16a2 2 0 01-2 2H2V2h9zm-7 8v4h7v-4H4zm0 6v4h7v-4H4zM4 4v4h7V4H4zm11 7h3V8h2v3h3v2h-3v3h-2v-3h-3v-2z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoTableColumnAddAfter;
