import React, { KeyboardEvent } from 'react';
import { InputNumber } from 'antd';
import './styles.less';

interface Props {
  onChange: (value: number | null) => void;
  value: number | null;
  onBlur: React.FocusEventHandler;
}

const PriceInput = React.forwardRef<HTMLInputElement, Props>(({ onChange, value, onBlur }, ref) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const isDotAlreadyOnValue = event.currentTarget.value.includes('.');

    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }

    const numericValues = /^[0-9\b]+$/;
    if (!numericValues.test(event.key) && event.key !== 'Backspace' && (event.key !== '.' || isDotAlreadyOnValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <InputNumber
        ref={ref}
        data-testid="document-price"
        placeholder="0.00"
        type="number"
        className="price-input"
        onKeyDown={handleKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    </>
  );
});

PriceInput.displayName = 'PriceInput';

export default PriceInput;
