import * as React from 'react';
const SvgIcoAddShape = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6.25a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V7A.75.75 0 015 6.25zM19 6.25a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V7a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17.75a.25.25 0 00-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25h-2zm-1.75.25c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0120 21.75h-2A1.75 1.75 0 0116.25 20v-2zM4 17.75a.25.25 0 00-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25H4zM2.25 18c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 016 21.75H4A1.75 1.75 0 012.25 20v-2zM18 3.75a.25.25 0 00-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 00.25-.25V4a.25.25 0 00-.25-.25h-2zM16.25 4c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0120 7.75h-2A1.75 1.75 0 0116.25 6V4zM4 3.75a.25.25 0 00-.25.25v2c0 .138.112.25.25.25h2A.25.25 0 006.25 6V4A.25.25 0 006 3.75H4zM2.25 4c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 016 7.75H4A1.75 1.75 0 012.25 6V4z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 19a.75.75 0 01.75-.75h10a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6.25 5A.75.75 0 017 4.25h10a.75.75 0 010 1.5H7A.75.75 0 016.25 5zM8.25 12a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoAddShape;
