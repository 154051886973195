import { useTranslation } from 'react-i18next';
import './styles.less';
import { Box, Card, CardContent, Icon, Tooltip } from '@mui/material';
import { Typography } from 'antd';
import { CreateAssetButton } from '../create-asset-button';
import Interests from '../../../../components/icon/icons/IcoInterests';
import Help from '../../../../components/icon/icons/IcoHelp';

export function EmptyState() {
  const { t } = useTranslation();
  const tooltipText = t('library.empty_state_tooltip_text');

  return (
    <Box display="flex" height="85vh" margin="auto" justifyContent="center" data-testid="empty-state-container">
      <Card className="empty-state-card">
        <CardContent className="empty-state-card-content">
          <Icon>
            <Interests />
          </Icon>
          <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
            <Box display="flex" alignItems="center">
              <Typography className="empty-state-title">{t('library.empty_state_title')}</Typography>
              <Tooltip title={tooltipText} placement="right">
                <Icon className="help-icon" data-testid="help-icon">
                  <Help />
                </Icon>
              </Tooltip>
            </Box>
            <Typography className="empty-state-text">{t('library.empty_state_text')}</Typography>
          </Box>
          <CreateAssetButton />
        </CardContent>
      </Card>
    </Box>
  );
}
