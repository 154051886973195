import * as React from 'react';
const SvgIcoSearch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M15.184 7.369a5.526 5.526 0 10-7.815 7.815 5.526 5.526 0 007.815-7.815zm1.06-1.061a7.026 7.026 0 10-9.936 9.936 7.026 7.026 0 009.936-9.936z" />
      <path d="M15.18 15.18a.75.75 0 011.06 0l3.29 3.29a.75.75 0 11-1.06 1.06l-3.29-3.29a.75.75 0 010-1.06z" />
    </g>
  </svg>
);
export default SvgIcoSearch;
