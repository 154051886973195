import * as React from 'react';
const SvgIcoFolder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 -1.5 35 35" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.431 9.155h20.958c2.158 0 2.158-2.238.084-2.238H14.987c-.83 0-1.244-1.244-1.244-1.244S13.162 3.848 12 3.848H1.211C-.365 3.848.049 5.673.049 5.673s2.407 20.47 2.573 21.715S4.075 29 4.075 29l2.821-18.103c.208-1.327 1.12-1.7 1.535-1.742zm25.227.787H9.095c-.733 0-1.328.594-1.328 1.327l-2.572 16.4c0 .734.595 1.328 1.328 1.328h24.563c.732 0 1.328-.594 1.328-1.328l2.572-16.4c0-.733-.593-1.327-1.328-1.327z"
    />
  </svg>
);
export default SvgIcoFolder;
