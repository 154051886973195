import GridDeleteComponent from './GridDeleteComponent';
import './BlockSettingsToolsContainer.less';
import BlockSettingsIconButton from './BlockSettingsIconButton';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { useBlockContent } from '../../Sections/useBlockContent';
import { useEffect, useState } from 'react';
interface BlockSettingsToolsProps {
  blockId: string;
}

type BlockSettingsContainerPositionClassType = 'at-the-top' | 'at-the-bottom';
export function BlockSettingsToolsContainer({ blockId }: BlockSettingsToolsProps) {
  const blockContent = useBlockContent(blockId);
  const [positionClass, setPositionClass] = useState<BlockSettingsContainerPositionClassType>('at-the-top');

  useEffect(() => {
    if (blockContent && blockContent.blockConfig.y < 0) {
      setPositionClass('at-the-bottom');
    } else {
      setPositionClass('at-the-top');
    }
  }, [blockContent?.blockConfig?.y]);

  if (!blockContent) {
    return <></>;
  }

  const className = 'block-settings-container ' + positionClass;
  return (
    <div data-testid={`block-settings-container-${blockId}`} className={className}>
      <GridDeleteComponent blockId={blockId} />
      {(blockContent.type === GridBlockType.TEXT || blockContent.type === GridBlockType.IMAGE) && (
        <BlockSettingsIconButton blockId={blockId} type={GridBlockType[blockContent.type]} />
      )}
    </div>
  );
}
