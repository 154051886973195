import { useTranslation } from 'react-i18next';
import './styles.less';
import { Typography, Box, Dialog, IconButton } from '@mui/material';
import CloseX from '../../../../components/icon/icons/IcoCloseX';
import { AssetList } from 'pages/library/components/asset-list';
import { StartFromScratchButton } from '../start-from-scratch-button';
import { useContentLibraryListQuery } from 'hooks/useContentLibraryListQuery';
import { CreateDocumentModalEmptyState } from './empty-state';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/urls';
import { Asset } from 'services/library/entities/Asset';
import { openNotification } from 'components/notification';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function CreateDocumentModal(props: Props) {
  if (!props.open) {
    return <></>;
  }

  return CreateDocumentModalComponent(props);
}

function CreateDocumentModalComponent({ open, handleClose }: Props) {
  const { visibleAssetsList } = useContentLibraryListQuery();
  const { createDocument } = useCreateDocumentMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAssetClickCreateDocumentHandler = async (asset: Asset) => {
    let documentId: string;
    try {
      const createdDocument = await createDocument({ title: asset.name, price: asset.price, copyFromContentId: asset.content_id });
      documentId = createdDocument.documentId;
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.error_title'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
      return;
    }
    navigate(`${URLS.document}/${documentId}`);
  };

  return (
    <Dialog
      className="create-document-modal"
      data-testid="create-document-modal"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: '28px' } }}
      maxWidth="md"
      fullWidth
    >
      <Box className="modal-container" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {visibleAssetsList.length > 0 ? (
              <Typography className="create-document-title">{t('pipeline.select_template_modal.select_template')}</Typography>
            ) : null}
          </Box>
          <Box>
            <IconButton
              className="create-document-close-button"
              data-testid="create-document-close-button"
              onClick={handleClose}
              disableRipple
            >
              <CloseX />
            </IconButton>
          </Box>
        </Box>
        {visibleAssetsList.length === 0 ? (
          <CreateDocumentModalEmptyState />
        ) : (
          <>
            <Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography className="create-document-modal-sort-by-caption">{t('pipeline.select_template_modal.sort_by')}</Typography>
              </Box>
              <Box className="create-document-asset-list">
                <AssetList onAssetClick={onAssetClickCreateDocumentHandler} />
              </Box>
            </Box>
            <Box>
              <StartFromScratchButton />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
