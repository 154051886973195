import { useTranslation } from 'react-i18next';

import { Heading } from 'components/typography';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import { DocumentSettingsForm } from './form';
import { LoadingIndicator } from './loading-indicator';

import './documentSettings.less';
import { useState, useEffect, useContext } from 'react';
import { DocumentDesignSettings } from './DocumentDesignSettings/DocumentDesignSettings';
import SecondaryMenuTrigger from '../component/SecondaryMenuTrigger';
import { SecondaryMenuWrapper } from '../component';
import { SidePanelProviderContext, AdvancedSpacingContext } from '../content';

interface CurrentModelProps {
  documentSettings: boolean;
  documentDesignSettings: boolean;
  isAdvancedSpacingModelOpen: boolean;
}

export function DocumentSettings({ documentId, activeTab }: { documentId: string; activeTab: string }) {
  const { data } = useGetDocumentByIdQuery(documentId);
  const { t } = useTranslation();
  const [isDesignSettingsModelOpen, setIsDesignSettingsModelOpen] = useState<boolean>(false);
  const { toggledDesignSettingModelType } = useContext(SidePanelProviderContext);
  const { isAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const defaultModelState = {
    documentSettings: !isDesignSettingsModelOpen,
    documentDesignSettings: isDesignSettingsModelOpen,
    isAdvancedSpacingModelOpen,
  };
  const [currentModel, setCurrentModel] = useState<CurrentModelProps>(defaultModelState);
  const isDocumentSettingsTriggered = currentModel.documentSettings;
  const isDocumentDesignSettingsTriggered = currentModel.documentDesignSettings;

  useEffect(() => {
    if (isAdvancedSpacingModelOpen) {
      setCurrentModel({
        documentSettings: false,
        documentDesignSettings: false,
        isAdvancedSpacingModelOpen: isAdvancedSpacingModelOpen,
      });
    } else {
      setCurrentModel(defaultModelState);
    }
  }, [isDesignSettingsModelOpen, isAdvancedSpacingModelOpen, toggledDesignSettingModelType]);

  const handleDesignSettingsClick = () => {
    setIsDesignSettingsModelOpen(true);
  };

  useEffect(() => {
    setIsDesignSettingsModelOpen(false);
  }, [activeTab]);

  switch (true) {
    case isDocumentSettingsTriggered:
      return (
        <SecondaryMenuWrapper isSecondaryMenuActive={isDocumentSettingsTriggered} testId={'document-settings-component'}>
          <Heading level={5}>{t('document.form.title')}</Heading>
          {data ? <DocumentSettingsForm data={data} /> : <LoadingIndicator />}
          <SecondaryMenuTrigger
            menuTriggerTitle={t('document.form.style')}
            menTriggerDesc={t('document.form.dec')}
            handleClick={handleDesignSettingsClick}
          />
        </SecondaryMenuWrapper>
      );
    case isDocumentDesignSettingsTriggered:
      return (
        <SecondaryMenuWrapper isSecondaryMenuActive={isDocumentDesignSettingsTriggered}>
          <DocumentDesignSettings setIsDesignSettingsModelOpen={setIsDesignSettingsModelOpen} />
        </SecondaryMenuWrapper>
      );
    default:
      return null;
  }
}
