import * as React from 'react';
const SvgIcoIntegrations = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M9.499 11.5a.25.25 0 00-.25.25V13c0 .83.672 1.501 1.5 1.501h2.502c.829 0 1.5-.672 1.5-1.5V11.75a.25.25 0 00-.25-.25H9.499zm-1.75.25c0-.967.783-1.75 1.75-1.75h5.002c.967 0 1.75.783 1.75 1.75V13a3.001 3.001 0 01-3 3.001h-2.502a3.001 3.001 0 01-3-3V11.75z" />
      <path d="M10.035 7.749a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V8.5a.75.75 0 01.75-.75zM13.965 7.749a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V8.5a.75.75 0 01.75-.75z" />
      <path d="M10.499 2.366a9.748 9.748 0 015.837 18.36.75.75 0 01-.668-1.343A8.248 8.248 0 103.746 12a8.604 8.604 0 002.525 6.096 6.235 6.235 0 004.4 1.818.58.58 0 00.579-.58v-4.083a.75.75 0 111.5 0v4.084a2.08 2.08 0 01-2.078 2.079 7.737 7.737 0 01-5.46-2.256A10.104 10.104 0 012.246 12 9.748 9.748 0 0110.5 2.366z" />
    </g>
  </svg>
);
export default SvgIcoIntegrations;
