import * as React from 'react';
const SvgIcoCircleCloseX = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.641 6.641a.75.75 0 011.06 0l5.658 5.657a.75.75 0 01-1.06 1.06L6.64 7.703a.75.75 0 010-1.06z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.641 13.359a.75.75 0 010-1.06l5.657-5.658a.75.75 0 011.06 1.06L7.703 13.36a.75.75 0 01-1.06 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.834 4.166a8.25 8.25 0 00-11.668 0 8.25 8.25 0 000 11.668 8.25 8.25 0 0011.668 0 8.25 8.25 0 000-11.668zM3.106 3.106a9.75 9.75 0 0113.788 0 9.75 9.75 0 010 13.788 9.75 9.75 0 01-13.788 0 9.75 9.75 0 010-13.788z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoCircleCloseX;
