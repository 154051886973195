import { useEffect, useContext } from 'react';

import { SecondaryMenuWrapper } from '../component';
import { SidePanelProviderContext, TableSettingsTypes } from './SidePanelModelsProvider';
import { TextBlockSettings } from './designSettings/textblock';
import { AdvancedSpacing } from './designSettings/AdvancedSpacing';
import { ImageBlockSettings } from './designSettings/ImageBlock/ImageBlockSettings';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { RowSettings, ColumnSettings } from './TableSettings';
import { AdvancedSpacingContext } from './designSettings/AdvancedSpacing';

export const SidePanelModelsAvailable = {
  DESIGN_SETTINGS_TEXT: TextBlockSettings,
  DESIGN_SETTINGS_IMAGE: ImageBlockSettings,
  TABLE_SETTINGS_ROWS: RowSettings,
  TABLE_SETTINGS_COLUMNS: ColumnSettings,
  ADVANCED_SPACING_SETTINGS: AdvancedSpacing,
};

export type SidePanelModelsAvailableType = (() => JSX.Element) | null;

export default function SidePanelModels({ activeTab }: { activeTab: string }) {
  const { toggledDesignSettingModelType, toggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { isAdvancedSpacingModelOpen, setIsAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);

  let ModelToRender: SidePanelModelsAvailableType = null;

  if (toggledDesignSettingModelType) {
    if (!isAdvancedSpacingModelOpen) {
      switch (toggledDesignSettingModelType) {
        case GridBlockType.TEXT:
          ModelToRender = SidePanelModelsAvailable.DESIGN_SETTINGS_TEXT;
          break;
        case GridBlockType.IMAGE:
          ModelToRender = SidePanelModelsAvailable.DESIGN_SETTINGS_IMAGE;
          break;
      }
    } else {
      ModelToRender = SidePanelModelsAvailable.ADVANCED_SPACING_SETTINGS;
    }
  } else if (toggledTableSettingsPanel) {
    switch (toggledTableSettingsPanel.tableSettingsPanel) {
      case TableSettingsTypes.TABLE_ROWS:
        ModelToRender = SidePanelModelsAvailable.TABLE_SETTINGS_ROWS;
        break;
      case TableSettingsTypes.TABLE_COLUMNS:
        ModelToRender = SidePanelModelsAvailable.TABLE_SETTINGS_COLUMNS;
        break;
    }
  }

  useEffect(() => {
    setIsAdvancedSpacingModelOpen(false);
  }, [activeTab]);

  if (!ModelToRender) return null;

  return (
    <SecondaryMenuWrapper isSecondaryMenuActive={true}>
      <ModelToRender />
    </SecondaryMenuWrapper>
  );
}
