import { GridBlockType } from '../shared/gridBlockType';
import './style.less';
import { useContext, useMemo } from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { useDocumentImageLibrary } from '../../../hooks/useDocumentImageLibrary';
import { DocumentImageLibraryApiResponse } from '../../../services/repositories/implementations/ApiDocumentRepository';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';
import ImageUploadButton from './ImageUploadButton/ImageUploadButton';
import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/Store';
import { BlocksContentCollection } from '../grid/reduxStore/editorSlice';
import { EditorContentContext } from 'providers/EditorContentContext';

export default function ImageLibrary() {
  const { contentId } = useContext(EditorContentContext);

  const IMAGE_LIBRARY_COLUMNS = 2,
    IMAGE_LIBRARY_GAP = 8;

  //TODO once we implement lazy load of sections, we will have to re-think this one
  const allSections = useSelector((state: RootState) => state.gridBlockReducer.sections);
  const gridBlocks: BlocksContentCollection = Object.values(allSections).reduce((acc, section) => {
    return { ...acc, ...section.blocksContent };
  }, {} as BlocksContentCollection);

  const imageQueryString = '?w=248&fit=crop&auto=format';
  const imageQueryStringHighRes = '?w=248&fit=crop&auto=format&dpr=2 2x';
  const { result, isError, isFetched, refetch } = useDocumentImageLibrary(contentId);
  const { t } = useTranslation();
  let libraryImages = result;

  if (isError) {
    openNotification({
      type: 'error',
      title: t('document.image.errors.library_image_load_fail'),
      description: '',
      placement: 'topRight',
    });
  }

  // Filter out unique image grid blocks
  const imageList = useMemo(() => {
    const uniqueUrls = new Set<string>();

    for (const key in gridBlocks) {
      if (gridBlocks[key].type === GridBlockType.IMAGE) {
        uniqueUrls.add(gridBlocks[key].content);
      }
    }

    if (isFetched) {
      (libraryImages as DocumentImageLibraryApiResponse).forEach((image) => {
        uniqueUrls.add(image.link);
      });
    }

    return uniqueUrls;
  }, [gridBlocks, isFetched, libraryImages]);

  const refreshImages = async () => {
    const response = await refetch();
    if (response?.data) {
      libraryImages = response.data;
    }
  };

  return (
    <div className="image__library__container">
      <div className="image__container_title">IMAGES</div>
      <div className="image__heading__section">LIBRARY</div>
      <div className="image__content__section">
        <Box className="image__library__box">
          <ImageList variant="masonry" cols={IMAGE_LIBRARY_COLUMNS} gap={IMAGE_LIBRARY_GAP} draggable={true}>
            <ImageListItem key={0}>
              <ImageUploadButton refreshImages={refreshImages} />
            </ImageListItem>
            {Array.from(imageList).map((item, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`${item}${imageQueryStringHighRes}`}
                  src={`${item}${imageQueryString}`}
                  alt={`Image ${index}`}
                  loading="lazy"
                  draggable={true}
                  onDragStart={(event) => {
                    event.dataTransfer.setData('text/uri-list', item);
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
}

ImageLibrary.displayName = 'ImageLibrary';
