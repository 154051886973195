import * as React from 'react';
const SvgIcoScreenSearchDesktopOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="currentColor" fillOpacity={0.5} d="M9 11h38v27H9z" />
    <path
      d="M36.05 35.117l2.566-2.45-4.9-4.9a7.912 7.912 0 00.964-2.1 8.267 8.267 0 00.32-2.334c0-2.294-.808-4.23-2.422-5.805-1.615-1.576-3.53-2.363-5.745-2.361-2.217 0-4.132.787-5.747 2.361-1.615 1.574-2.421 3.51-2.42 5.805 0 2.295.808 4.23 2.422 5.806 1.615 1.576 3.53 2.363 5.745 2.361a8.03 8.03 0 002.305-.322 9.042 9.042 0 002.128-.961l4.784 4.9zM26.833 28c-1.283 0-2.382-.457-3.297-1.372s-1.371-2.013-1.37-3.295c0-1.283.458-2.382 1.372-3.297.915-.914 2.013-1.37 3.295-1.37 1.245 0 2.333.458 3.267 1.373.933.914 1.4 2.013 1.4 3.294 0 1.284-.458 2.383-1.372 3.297-.915.915-2.013 1.372-3.295 1.37zM4.666 49c-.66 0-1.215-.224-1.663-.672-.448-.448-.672-1.002-.67-1.661 0-.661.224-1.216.672-1.664.448-.448 1.002-.671 1.661-.67h46.667c.661 0 1.216.224 1.664.672.448.448.67 1.002.67 1.662 0 .66-.225 1.215-.673 1.663-.448.448-1.001.672-1.661.67H4.666zm4.667-7c-1.283 0-2.382-.457-3.297-1.372s-1.371-2.013-1.37-3.295V11.667c0-1.284.458-2.383 1.372-3.297.915-.915 2.013-1.372 3.295-1.37h37.333c1.284 0 2.383.457 3.297 1.372.915.915 1.372 2.013 1.37 3.295v25.666c0 1.284-.457 2.383-1.372 3.297-.915.915-2.013 1.372-3.295 1.37H9.333zm0-4.667h37.333V11.667H9.333v25.666z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoScreenSearchDesktopOutline;
