import { useContext, useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  SecondaryMenu,
  SecondaryMenuSectionTitle,
  ColorPickerSection,
  NumInputBoxTwoCol,
  TextInputBoxSingleCol,
  DropDownSection,
} from '../../../component';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';
import SecondaryMenuTrigger from '../../../component/SecondaryMenuTrigger';
import { AdvancedSpacingContext } from '../AdvancedSpacing/AdvancedSpacingProvider';
import { BlockStyleSettingsContext } from '../AdvancedSpacing/BlockSettingsProvider';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { getBlockSidesFromString } from '../../../../GridDndEditor/Block/Helper/BlockSides';
import { cleanUrl } from '../../../../../../utils/cleanUrl';

export function ImageBlockSettings() {
  const { t } = useTranslation();
  const { setToggledDesignSettingModelType } = useContext(SidePanelProviderContext);
  const { setIsAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const { selectedBlockIdByIcon } = useContext(SelectionContext);

  const { setBlockBorderColor, onDisplayChange, getBlockStyle, onBorderWidthChange, onBorderRadiusChange, setBlockBorderSide } =
    useContext(BlockStyleSettingsContext);
  const [displaySectionValue, setDisplaySectionValue] = useState({ opacity: 0, imageAlt: '', imageLink: '' });

  useEffect(() => {
    if (selectedBlockIdByIcon) {
      const { opacity, imageAlt, imageLink } = getBlockStyle(selectedBlockIdByIcon);
      setDisplaySectionValue({
        opacity: opacity ? opacity : 0,
        imageAlt: imageAlt ? imageAlt : '',
        imageLink: imageLink ? imageLink : '',
      });
    }
  }, [selectedBlockIdByIcon, getBlockStyle]);

  const handleOpacityChange = (val) => {
    setDisplaySectionValue((prev) => ({ ...prev, opacity: val }));
    onDisplayChange({ ...displaySectionValue, opacity: val });
  };

  const handleImageAltChange = (val) => {
    setDisplaySectionValue((prev) => ({ ...prev, imageAlt: val }));
    onDisplayChange({ ...displaySectionValue, imageAlt: val });
  };

  const handleImageLinkChange = (val: string) => {
    setDisplaySectionValue((prev) => ({ ...prev, imageLink: val }));
    onDisplayChange({ ...displaySectionValue, imageLink: val });
  };

  const handleImageLinkBlur = (val: string) => {
    const newVal = cleanUrl(val);
    setDisplaySectionValue((prev) => ({ ...prev, imageLink: newVal }));
    onDisplayChange({ ...displaySectionValue, imageLink: newVal });
  };

  const { borderColor, currentBorderSide = [], borderRadius = 0, borderWidth = 0 } = getBlockStyle(selectedBlockIdByIcon || '');
  const handleBackButtonClick = () => {
    setToggledDesignSettingModelType(null);
  };

  return (
    <SecondaryMenu
      testId={'image-block-settings-component'}
      menuTitle={t('document.grid.image.design.title')}
      backButtonOnClick={handleBackButtonClick}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.image.design.display')}</SecondaryMenuSectionTitle>
        <NumInputBoxTwoCol
          title={t('document.grid.image.design.opacity')}
          ariaLabel="%"
          inputValue={displaySectionValue.opacity}
          onChange={handleOpacityChange}
          limitedNum={100}
          disableNegativeNum
        />

        <TextInputBoxSingleCol
          title={t('document.grid.image.design.image_alt_text')}
          descriptionText={t('document.grid.image.design.image_alt_text_dec')}
          onChange={handleImageAltChange}
          value={displaySectionValue.imageAlt}
        />

        <TextInputBoxSingleCol
          title={t('document.grid.image.design.link')}
          descriptionText={t('document.grid.image.design.link_dec')}
          onChange={handleImageLinkChange}
          onBlur={handleImageLinkBlur}
          value={displaySectionValue.imageLink}
          startAdornmentText={'https://'}
        />
      </Stack>
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.image.design.border')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={borderColor} handleColorChange={setBlockBorderColor} />
        <DropDownSection
          currentSelection={currentBorderSide}
          handleSelectionChange={(borderSideStr) => {
            setBlockBorderSide(getBlockSidesFromString(borderSideStr));
          }}
          testId={'border-dropdown'}
        />
        <NumInputBoxTwoCol
          inputValue={borderWidth}
          onChange={onBorderWidthChange}
          title={t('document.grid.text.design.border_width')}
          testId={'border-width'}
          disableNegativeNum
        />
        <NumInputBoxTwoCol
          title={t('document.grid.text.design.border_radius')}
          inputValue={borderRadius}
          disableNegativeNum
          onChange={onBorderRadiusChange}
        />

        <SecondaryMenuTrigger
          menuTriggerTitle={t('document.grid.text.advanced_spacing.title')}
          menTriggerDesc={t('document.grid.text.advanced_spacing.padding')}
          handleClick={() => {
            setIsAdvancedSpacingModelOpen(true);
          }}
        />
      </Stack>
    </SecondaryMenu>
  );
}
