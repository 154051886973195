import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'components';
import './styles.less';
import { Dropdown, Menu } from 'antd';
import { constructCinderUrl } from 'utils/constructCinderUrl';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useNavigate } from 'react-router-dom';
import { BetaBadge } from '../beta-badge';
import { CreateDocumentModal } from '../create-document-modal';

export function CreateDocumentButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const featureFlags = useFeatureFlag([FeatureFlags.editorV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createDocumentButtonMenu = (
    <Menu>
      <Menu.Item key="1">
        <a href={constructCinderUrl(CINDER_URLS.createDocument)}>{t('pipeline.create_document_button.dropdown_editor_v2')}</a>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleOpen}>
        <div>
          <BetaBadge />
          {t('pipeline.create_document_button.dropdown_editor_v3')}
        </div>
      </Menu.Item>
    </Menu>
  );

  return isEditorV3Enabled ? (
    <>
      <Dropdown overlayClassName="create-document-dropdown" trigger={['click']} dropdownRender={() => createDocumentButtonMenu}>
        <Button
          icon={<Icon name="IcoPencil" />}
          className="create-document-button"
          type="primary"
          variant="positive"
          onClick={(e: MouseEvent) => e.preventDefault()}
          data-testid="create-document-button"
        >
          {t('pipeline.create_document_button.title')}
        </Button>
      </Dropdown>
      <CreateDocumentModal open={open} handleClose={handleClose} />
    </>
  ) : (
    <Button
      icon={<Icon name="IcoPencil" />}
      className="create-document-button"
      type="primary"
      variant="positive"
      onClick={() => navigate(CINDER_URLS.createDocument)}
      data-testid="create-document-button"
    >
      {t('pipeline.create_document_button.title')}
    </Button>
  );
}
