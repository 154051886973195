import * as React from 'react';
const SvgIcoOrderedList = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.541 1.333c0-.345.28-.625.625-.625h7.5a.625.625 0 110 1.25h-7.5a.625.625 0 01-.625-.625zM6.666 5.5c0-.345.28-.625.625-.625h7.375a.625.625 0 110 1.25H7.291a.625.625 0 01-.625-.625zM2.614.756A.625.625 0 013 1.333v3.542h.416a.625.625 0 110 1.25H1.333a.625.625 0 110-1.25h.417V2.841a.625.625 0 01-.859-.908L1.933.891a.625.625 0 01.68-.135zM6.541 10.5c0-.345.28-.625.625-.625h7.5a.625.625 0 110 1.25h-7.5a.625.625 0 01-.625-.625zM6.666 14.667c0-.345.28-.625.625-.625h7.375a.625.625 0 110 1.25H7.291a.625.625 0 01-.625-.625zM.708 10.5c0-.345.28-.625.625-.625h2.083c.346 0 .625.28.625.625v1.667c0 .22-.115.423-.303.536l-1.78 1.068v.27h1.667a.625.625 0 110 1.25H1.333a.625.625 0 01-.625-.624v-1.25c0-.22.115-.423.303-.536l1.78-1.068v-.688H1.333a.625.625 0 01-.625-.625z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoOrderedList;
