import { IconButton } from '@mui/material';
import { useContext } from 'react';

import { useBlockDeletedHandler } from '../../hooks/UseBlockDeletedHandler';

import { SidePanelProviderContext } from '../../SidePanel/content';
import { BlockStyleSettingsContext } from '../../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import IcoTrash from '../../../icon/icons/IcoTrash';

import { useSectionData } from '../../Sections/useSectionContext';
import '../GridDndEditor.less';

export interface GridDeleteComponentProp {
  blockId: string;
}
export default function GridDeleteComponent({ blockId }: GridDeleteComponentProp) {
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const { resetAllPanels } = useContext(SidePanelProviderContext);
  const { deleteBlocksSettings } = useContext(BlockStyleSettingsContext);
  const { blocksContent, sectionId } = useSectionData();

  const handleDeleteOk = () => {
    const blockContent = blocksContent[blockId];
    deleteBlocksSettings(blockId);
    gridBlockDeletedHandler(sectionId, blockId, blockContent).then(() => {
      resetAllPanels();
    });
  };

  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };

  return (
    <>
      <IconButton size={'small'} sx={iconButtonSX} onClick={() => handleDeleteOk()}>
        <IcoTrash className="editor__page__draggable__grid-delete-handle" />
      </IconButton>
    </>
  );
}
