import { useState, useEffect, useContext } from 'react';
import { Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon';
import { IconNamesType } from 'components/icon/icons';
import EditorToolbar from '../toolbar/toolbar';
import { Signatures } from './Signatures';
import { Variables } from './Variables';
import './styles.less';
import { EditorRefType } from '../../../hooks/useEditorRef';
import { SidePanelModels, SidePanelProviderContext } from './content';
import { ContentType, getContentType } from 'utils/getContentType';
import { RenderEditorSidePanelSettings } from './RenderEditorSidePanelSettings';
import { FeatureFlags } from '../../../utils/featureFlags';
import useFeatureFlag from '../../../hooks/useFeatureFlag';

interface Props {
  editorRef: EditorRefType;
  documentId: string;
}

export function EditorSidePanel({ editorRef, documentId }: Props) {
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.spVariables]);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('document_tab');
  const { toggledDesignSettingModelType, setToggledDesignSettingModelType, toggledTableSettingsPanel, setToggledTableSettingsPanel } =
    useContext(SidePanelProviderContext);
  const isEditorToolbarDisplayed = !toggledTableSettingsPanel && !toggledDesignSettingModelType;
  const isVariablesTabVisible = evaluatedFeatureFlags[FeatureFlags.spVariables] || false;

  const handleTabChange = (key: string) => {
    if (key != 'content_tab') {
      setToggledDesignSettingModelType(null);
      setToggledTableSettingsPanel(null);
    }
    setActiveTab(key);
  };

  useEffect(() => {
    if (toggledDesignSettingModelType || toggledTableSettingsPanel) {
      setActiveTab('content_tab');
    }
  }, [toggledDesignSettingModelType, toggledTableSettingsPanel]);

  const contentType = getContentType();

  const navItems: { key: string; label: string; icon: IconNamesType; children?: React.ReactNode }[] = [
    {
      key: 'document_tab',
      label: contentType === ContentType.DOCUMENT ? t('editor.nav_tab.document') : t('editor.nav_tab.template'),
      icon: 'IcoSettingsLibrary',
      children: <RenderEditorSidePanelSettings documentId={documentId} activeTab={activeTab} />,
    },
    {
      key: 'content_tab',
      label: t('editor.nav_tab.content'),
      icon: 'IcoAddShape',
      children: (
        <>
          <SidePanelModels activeTab={activeTab} />
          {isEditorToolbarDisplayed && <EditorToolbar editorRef={editorRef} />}
        </>
      ),
    },
    {
      key: 'signatures_tab',
      label: t('editor.nav_tab.signatures'),
      icon: 'IcoSignaturePen',
      children: <Signatures />,
    },
    {
      key: 'variables_tab',
      label: t('editor.nav_tab.variables'),
      icon: 'IcoVariables',
      children: <Variables />,
    },
  ];
  const navItemsWithoutVariables = isVariablesTabVisible ? navItems : navItems.filter((item) => item.key !== 'variables_tab');
  return (
    <Tabs
      className={'editor-sidepanel-nav'}
      tabPosition="left"
      defaultActiveKey="content"
      activeKey={activeTab}
      onChange={handleTabChange}
      items={navItemsWithoutVariables.map(({ key, label, icon, children }) => ({
        label: <NavItem id={key} label={label} icon={icon} />,
        key: key,
        children: children,
      }))}
    />
  );
}

function NavItem({ id, label, icon }: { id: string; label: string; icon: IconNamesType }) {
  const [isOpenTab, setIsOpenTab] = useState(false);

  const handleMouseEnter = () => {
    setIsOpenTab(true);
  };

  const handleMouseLeave = () => {
    setIsOpenTab(false);
  };

  useEffect(() => {
    const hideTooltip = () => {
      setIsOpenTab(false);
    };
    window.addEventListener('scroll', hideTooltip);
    return () => {
      window.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <Tooltip placement="left" title={label} open={isOpenTab}>
      <span id={id} className="editor-sidepanel-nav__icon-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Icon name={icon} />
      </span>
    </Tooltip>
  );
}
