import { Typography } from '@mui/material';
import { DocumentDesignSettings } from '../document-settings/DocumentDesignSettings/DocumentDesignSettings';
import { useContext, useEffect, useState } from 'react';
import { SecondaryMenuTrigger, SecondaryMenuWrapper } from '../component';
import { SidePanelProviderContext, AdvancedSpacingContext } from '../content';
import { useTranslation } from 'react-i18next';

interface CurrentModelProps {
  documentSettings: boolean;
  documentDesignSettings: boolean;
  isAdvancedSpacingModelOpen: boolean;
}

export function TemplateSettings({ activeTab }: { activeTab: string }) {
  const { t } = useTranslation();
  const [isDesignSettingsModelOpen, setIsDesignSettingsModelOpen] = useState<boolean>(false);
  const { toggledDesignSettingModelType } = useContext(SidePanelProviderContext);
  const { isAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const defaultModelState = {
    documentSettings: !isDesignSettingsModelOpen,
    documentDesignSettings: isDesignSettingsModelOpen,
    isAdvancedSpacingModelOpen,
  };
  const [currentModel, setCurrentModel] = useState<CurrentModelProps>(defaultModelState);
  const isDocumentSettingsTriggered = currentModel.documentSettings;
  const isDocumentDesignSettingsTriggered = currentModel.documentDesignSettings;

  useEffect(() => {
    if (isAdvancedSpacingModelOpen) {
      setCurrentModel({
        documentSettings: false,
        documentDesignSettings: false,
        isAdvancedSpacingModelOpen: isAdvancedSpacingModelOpen,
      });
    } else {
      setCurrentModel(defaultModelState);
    }
  }, [isDesignSettingsModelOpen, isAdvancedSpacingModelOpen, toggledDesignSettingModelType]);

  const handleDesignSettingsClick = () => {
    setIsDesignSettingsModelOpen(true);
  };

  useEffect(() => {
    setIsDesignSettingsModelOpen(false);
  }, [activeTab]);

  switch (true) {
    case isDocumentSettingsTriggered:
      return (
        <SecondaryMenuWrapper isSecondaryMenuActive={isDocumentSettingsTriggered} testId={'document-settings-component'}>
          <Typography color={'#011A26'} fontWeight={'600'}>
            {t('editor.side_panel.template_settings_title')}
          </Typography>
          <SecondaryMenuTrigger
            menuTriggerTitle={'Template Style'}
            menTriggerDesc={t('document.form.dec')}
            handleClick={handleDesignSettingsClick}
          />
        </SecondaryMenuWrapper>
      );
    case isDocumentDesignSettingsTriggered:
      return (
        <SecondaryMenuWrapper isSecondaryMenuActive={isDocumentDesignSettingsTriggered}>
          <DocumentDesignSettings setIsDesignSettingsModelOpen={setIsDesignSettingsModelOpen} />
        </SecondaryMenuWrapper>
      );
    default:
      return null;
  }
}
