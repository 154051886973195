import { AxiosError } from 'axios';
import { openNotification } from 'components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';
import { SaveStatusContext } from '../components/editor/providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../components/editor/shared/models/DocumentSaveStatus';
import { ErrorCode } from '../services/socket/SocketEvents';
import { UpdateDocumentPayload } from '../services/repositories/interfaces/DocumentRepository';

export default function useUpdateDocumentMutation() {
  const { setSaveStatus } = useContext(SaveStatusContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateDocumentPayload) => {
      setSaveStatus(DocumentSaveStatus.SAVING);
      return apiDocumentRepository.updateDocument(payload);
    },
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(QueryKeys.getDocumentById(payload.documentId));
      setSaveStatus(DocumentSaveStatus.SAVED);
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.status === 423) {
        setSaveStatus(DocumentSaveStatus.NOT_SAVED, ErrorCode.LOCKED);
      } else {
        openNotification({
          title: t('error'),
          description: t('document.menu.update_error'),
          type: 'error',
        });
      }
    },
  });
}
