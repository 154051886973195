import { SaveStatusContext } from 'components/editor/providers/SaveStatusProvider';
import { DocumentSaveStatus } from 'components/editor/shared/models/DocumentSaveStatus';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function SaveStatusText() {
  const { t } = useTranslation();
  const { currentSaveStatus } = useContext(SaveStatusContext);

  return <>{t(`document.save_statuses.${DocumentSaveStatus[currentSaveStatus.status]?.toLowerCase()}`)}</>;
}
