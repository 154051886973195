import * as React from 'react';
const SvgIcoTableRowPlusAfter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 10a2 2 0 01-2 2H4a2 2 0 01-2-2V3h2v2h4V3h2v2h4V3h2v2h4V3h2v7zM4 10h4V7H4v3zm6 0h4V7h-4v3zm10 0V7h-4v3h4zm-9 4h2v3h3v2h-3v3h-2v-3H8v-2h3v-3z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoTableRowPlusAfter;
