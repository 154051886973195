import * as React from 'react';
const SvgIcoImage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M6.998 3.746a3.252 3.252 0 00-3.252 3.252v10.004a3.252 3.252 0 003.252 3.252h10.004a3.252 3.252 0 003.252-3.252V6.998a3.252 3.252 0 00-3.252-3.252H6.998zM2.246 6.998a4.752 4.752 0 014.752-4.752h10.004a4.752 4.752 0 014.752 4.752v10.004a4.752 4.752 0 01-4.752 4.752H6.998a4.752 4.752 0 01-4.752-4.752V6.998z" />
      <path d="M4.822 12.235l-1.295 1.296a.75.75 0 01-1.061-1.06l1.296-1.297a3.162 3.162 0 014.471 0l4.297 4.297a.75.75 0 01-1.06 1.061l-4.297-4.297a1.662 1.662 0 00-2.35 0z" />
      <path d="M16.18 15.236a1.662 1.662 0 00-2.351 0l-6.298 6.298a.75.75 0 11-1.06-1.06l6.297-6.298a3.162 3.162 0 014.472 0l3.94 3.94a.75.75 0 01-1.06 1.061l-3.94-3.94z" />
    </g>
  </svg>
);
export default SvgIcoImage;
