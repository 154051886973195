import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-CA': ['en'],
      'fr-FR': ['fr'],
      default: ['en'],
    },
    preload: [
      'en',
      'fr',
      'pt-BR',
      'da-DK',
      'nl-NL',
      'fi-FI',
      'de-DE',
      'el-GR',
      'hi-IN',
      'it-IT',
      'ru-RU',
      'es-MX',
      'sv-SE',
      'nb-NO',
      'pl-PL',
    ],
    debug: true,
    detection: {
      order: ['cookie', 'htmlTag', 'path'],
      caches: ['cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/files/locales/{{lng}}/translations.json',
    },
  });

export default i18n;
