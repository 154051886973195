import * as React from 'react';
const SvgIcoSingleUserAddPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.257 9.889a9.53 9.53 0 11-7.146-7.146M19.124 6.458V3.292M17.541 4.876h3.167"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={12} cy={9.5} r={2.5} stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.5 16a2.498 2.498 0 012.289-1.5h4.422c.993 0 1.892.59 2.289 1.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoSingleUserAddPlus;
