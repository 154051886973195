import * as React from 'react';
const SvgIcoCirclePlusSolid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.6 19.6" width="1em" height="1em" {...props}>
    <path
      d="M9.8 0C4.4 0 0 4.4 0 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8S15.2 0 9.8 0zm4.6 10.7h-3.6v3.6c0 .5-.4.9-.9.9s-.9-.4-.9-.9v-3.6H5.2c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h3.6V5.2c0-.5.4-.9.9-.9s.9.4.9.9v3.6h3.6c.5 0 .9.4.9.9.2.6-.2 1-.7 1z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoCirclePlusSolid;
