import * as React from 'react';
const SvgIcoDashboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M12 3.251a.75.75 0 01.75.75V6.44a.75.75 0 01-1.5 0V4.001a.75.75 0 01.75-.75zM18.25 13a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM2.25 13a.75.75 0 01.75-.75h2a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zM18.89 6.11a.75.75 0 010 1.06l-4.956 4.956a.75.75 0 11-1.06-1.06L17.83 6.11a.75.75 0 011.06 0z" />
      <path d="M11.981 11.75a1.25 1.25 0 10-.001 2.499 1.25 1.25 0 00.001-2.499zm0-1.5a2.75 2.75 0 10-.001 5.499 2.75 2.75 0 00.001-5.499zM5.11 6.11a.75.75 0 011.06 0l2.12 2.12a.75.75 0 01-1.06 1.06L5.11 7.17a.75.75 0 010-1.06z" />
      <path d="M5.106 6.106a9.75 9.75 0 0113.788 0 9.75 9.75 0 010 13.788.75.75 0 01-1.06 0L15.71 17.77a.75.75 0 111.06-1.06l1.57 1.57a8.25 8.25 0 10-12.68 0l1.45-1.45a.75.75 0 011.06 1.06l-2.004 2.004a.75.75 0 01-1.06 0 9.75 9.75 0 010-13.788z" />
    </g>
  </svg>
);
export default SvgIcoDashboard;
