import React, { useState, useContext, useEffect } from 'react';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { BlockStyleSettingsContext } from './BlockSettingsProvider';

interface AdvancedSpacingProviderProps {
  children: React.ReactNode;
}

type PaddingStyleType = {
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

interface PaddingStyleWithIDsType extends PaddingStyleType {
  updatedByUserId: number;
  blockId: string;
}

interface AdvancedSpacingContextProps {
  isAdvancedSpacingModelOpen: boolean;
  setIsAdvancedSpacingModelOpen: (advancedSpacingModelStatus: boolean) => void;
  setPaddingValues: (paddingStyle: PaddingStyleType) => void;
  selectedBlocksPaddingValue: PaddingStyleWithIDsType;
}

const defaultPaddingStyle: PaddingStyleType = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
};

export const AdvancedSpacingContext = React.createContext<AdvancedSpacingContextProps>({} as AdvancedSpacingContextProps);

export function AdvancedSpacingProvider({ children }: AdvancedSpacingProviderProps) {
  const { selectedBlockIdByIcon: blockId } = useContext(SelectionContext);
  const { saveCurrentBlockStyle, getBlockStyle, currentBlockStyle, updateBlockStyleSettings } = useContext(BlockStyleSettingsContext);
  const [isAdvancedSpacingModelOpen, setIsAdvancedSpacingModelOpen] = useState<boolean>(false);
  const [paddingValues, setPaddingValues] = useState<PaddingStyleType>(defaultPaddingStyle);
  const selectedBlocksPaddingValue: PaddingStyleWithIDsType = getBlockStyle(blockId || '');

  const updatePaddingStyleSettings = (paddingStyle: PaddingStyleType) => {
    if (!blockId) return;
    const updatedPaddingStyle: PaddingStyleWithIDsType = {
      ...currentBlockStyle,
      ...paddingStyle,
    };

    updateBlockStyleSettings(updatedPaddingStyle);
    saveCurrentBlockStyle(updatedPaddingStyle);
  };

  const contextValue: AdvancedSpacingContextProps = {
    isAdvancedSpacingModelOpen,
    setIsAdvancedSpacingModelOpen,
    selectedBlocksPaddingValue,
    setPaddingValues,
  };

  useEffect(() => {
    updatePaddingStyleSettings(paddingValues);
  }, [paddingValues]);

  return <AdvancedSpacingContext.Provider value={contextValue}>{children}</AdvancedSpacingContext.Provider>;
}
