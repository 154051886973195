import { createContext, useState } from 'react';
import { SaveStatus } from '../shared/models/SaveStatus';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { AcknowledgmentResponseStatus, ErrorCode } from 'services/socket/SocketEvents';

interface SaveStatusContextType {
  currentSaveStatus: SaveStatus;
  setSaveStatus: (status: DocumentSaveStatus, errCode?: string) => void;
  updateSaveStatus: (response: { status: AcknowledgmentResponseStatus; errorCode: ErrorCode }) => void;
}

interface SaveStatusProviderProps {
  children: React.ReactNode;
}

export const SaveStatusContext = createContext<SaveStatusContextType>({} as SaveStatusContextType);

export function SaveStatusProvider({ children }: SaveStatusProviderProps) {
  const [currentSaveStatus, setCurrentSaveStatus] = useState<SaveStatus>({ status: DocumentSaveStatus.SAVED, errCode: '' });
  const updateSaveStatus = ({ status, errorCode }) => {
    if (status === AcknowledgmentResponseStatus.OK) {
      setCurrentSaveStatus({ status: DocumentSaveStatus.SAVED, errCode: '' });
    } else if (status === AcknowledgmentResponseStatus.FAILURE) {
      setCurrentSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: errorCode });
    }
  };

  const setSaveStatus = (status: DocumentSaveStatus, errCode = '') => {
    setCurrentSaveStatus({ status, errCode });
  };

  return (
    <SaveStatusContext.Provider
      value={{
        currentSaveStatus,
        setSaveStatus,
        updateSaveStatus,
      }}
    >
      {children}
    </SaveStatusContext.Provider>
  );
}
