import { ContentType, getContentType } from 'utils/getContentType';
import { TemplateEditorMenu } from '../TemplateEditorMenu/TemplateEditorMenu';
import { EditorMenu as DocumentEditorMenu } from '../EditorMenu';

interface Props {
  documentId: string;
  assetId: string;
}

export function RenderEditorMenu({ documentId, assetId }: Props) {
  const contentType = getContentType();

  if (contentType === ContentType.DOCUMENT) {
    return <DocumentEditorMenu documentId={documentId} />;
  } else {
    return <TemplateEditorMenu assetId={assetId} />;
  }
}
