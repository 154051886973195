export const FeatureFlags = {
  newShareExperience: 'react-share-experience',
  sso: 'single-sign-on',
  crmDataFlexibility: 'crm-data-flexibility',
  editorV3: 'editor-v3',
  documentSettings: 'document-settings',
  multiaccounts: 'account-multi-access',
  documentsV3: 'documents-v3',
  chatGPT: 'ChatGPTXFroala',
  spVariables: 'SP-Variables',
};
