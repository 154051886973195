import * as React from 'react';
const SvgIcoSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <circle cx={12} cy={12} r={9.004} />
      <path d="M8.443 12.34l2.167 2.167-.014-.014 4.891-4.891" />
    </g>
  </svg>
);
export default SvgIcoSuccess;
