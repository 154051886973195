import * as React from 'react';
const SvgIcoEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M5.293 15.619L15.619 5.293a1 1 0 011.413 0l1.676 1.676a1 1 0 010 1.413L8.381 18.707a.995.995 0 01-.706.293H5v-2.675c0-.265.105-.519.293-.706z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.75 7.16l3.09 3.09" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgIcoEdit;
