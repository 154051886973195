import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './index.css';

import { QueryProvider } from './providers/QueryProvider';
import { ErrorBoundary } from 'vendor/datadog';
import i18n from './utils/i18n';

import App from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  /* TODO: Gradually improve the error boundary to handle all the cases */
  <ErrorBoundary fallback="Oh no, Something went wrong. Please refresh the page." scope="app">
    <I18nextProvider i18n={i18n}>
      <QueryProvider>
        <App />
      </QueryProvider>
    </I18nextProvider>
  </ErrorBoundary>
);
