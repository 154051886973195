import * as React from 'react';
const SvgIcoCloudUpload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.25a.75.75 0 01.75.75v7a.75.75 0 11-1.5 0v-7a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.47 11.303a.75.75 0 011.06 0l2.167 2.167a.75.75 0 11-1.06 1.06L12 12.894l-1.637 1.636a.75.75 0 01-1.06-1.06l2.167-2.167z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.59 9.336c.742-2.919 3.383-5.086 6.535-5.086a6.755 6.755 0 016.75 6.75v.262A4.257 4.257 0 0122.81 15.5a4.256 4.256 0 01-4.25 4.25H16a.75.75 0 010-1.5h2.56a2.756 2.756 0 002.75-2.75 2.756 2.756 0 00-2.75-2.75h-.435a.75.75 0 01-.75-.75v-1a5.255 5.255 0 00-5.25-5.25 5.251 5.251 0 00-5.173 4.395.75.75 0 01-.702.627A3.739 3.739 0 002.69 14.5a3.75 3.75 0 003.75 3.75H8a.75.75 0 010 1.5H6.44a5.25 5.25 0 01-5.25-5.25c0-2.612 1.91-4.758 4.4-5.164z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoCloudUpload;
