import * as React from 'react';
const SvgIcoDocumentation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.496 19.003V4.997a2 2 0 012.001-2h13.006a2 2 0 012 2V15a2 2 0 01-2 2H5.497a2 2 0 00-2 2.002v0a2 2 0 002 2h13.006a2 2 0 002-2V14"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.502 7.655H7.498z" fill="currentColor" />
    <path d="M16.502 7.655H7.498" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.502 12H7.498z" fill="currentColor" />
    <path d="M16.502 12H7.498" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgIcoDocumentation;
