import { configureStore } from '@reduxjs/toolkit';
import { gridBlockReducer } from './editorSlice';
import { usedVariablesReducer } from './usedVariablesSlice';
import { allVariables } from './allVariableSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const rootStore = configureStore({
  reducer: { gridBlockReducer, usedVariablesReducer, allVariables },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof rootStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof rootStore.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
