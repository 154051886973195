import * as React from 'react';
const SvgIcoDoubleArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor">
      <path d="M17.59 18L19 16.59 14.42 12 19 7.41 17.59 6l-6 6 6 6z" />
      <path d="M11 18l1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6 6 6z" />
    </g>
  </svg>
);
export default SvgIcoDoubleArrowLeft;
