import * as React from 'react';
const SvgIcoDollarSignSolid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 0a9 9 0 100 18A9 9 0 009 0zm.88 14.09v.58a.88.88 0 01-1.75 0v-.6a3.06 3.06 0 01-2.91-2.85.892.892 0 011.78-.11 1.31 1.31 0 001.3 1.23h1.6a1.18 1.18 0 001.1-1.17 1.17 1.17 0 00-.85-1.17l-2.73-.69a2.92 2.92 0 01.71-5.76V3a.88.88 0 111.75 0v.6a3 3 0 012.89 2.84.887.887 0 11-1.77.12 1.32 1.32 0 00-1.3-1.23H8.1a1.17 1.17 0 00-.28 2.31l2.73.69a2.92 2.92 0 01-.7 5.76h.03z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoDollarSignSolid;
