import { useTranslation } from 'react-i18next';
import './styles.less';
import { Box, Button, Icon, Tooltip } from '@mui/material';
import Interests from '../../../../../components/icon/icons/IcoInterests';
import Help from '../../../../../components/icon/icons/IcoHelp';
import { Typography } from 'antd';
import { StartFromScratchButton } from '../../start-from-scratch-button';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/urls';

export function CreateDocumentModalEmptyState() {
  const { t } = useTranslation();
  const tooltipText = t('library.empty_state_tooltip_text');
  const navigate = useNavigate();

  return (
    <Box
      className="select-template-empty-state"
      data-testid="select-template-empty-state"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="24px"
    >
      <Icon className="interests-icon">
        <Interests />
      </Icon>
      <Box display="flex" alignItems="center">
        <Typography className="select-template-empty-state-title">{t('pipeline.select_template_modal.empty_state_title')}</Typography>
        <Tooltip title={tooltipText} placement="right">
          <Icon className="help-icon" data-testid="help-icon">
            <Help />
          </Icon>
        </Tooltip>
      </Box>
      <Typography className="select-template-empty-state-text">{t('pipeline.select_template_modal.empty_state_text')}</Typography>
      <Box display="flex" gap="12px">
        <Button
          className="go-to-library-button"
          data-testid="go-to-library-button"
          onClick={() => {
            navigate(URLS.library);
          }}
        >
          {t('pipeline.select_template_modal.go_to_library')}
        </Button>
        <StartFromScratchButton />
      </Box>
    </Box>
  );
}
