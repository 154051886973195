import { useTranslation } from 'react-i18next';

import './styles.less';
import { Button } from '@mui/material';
import { URLS } from 'utils/urls';
import { useNavigate } from 'react-router-dom';

export function StartFromScratchButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      className="start-from-scratch-button"
      data-testid="start-from-scratch-button"
      disableRipple
      onClick={() => {
        navigate(URLS.document);
      }}
    >
      {t('pipeline.select_template_modal.start_from_scratch_button')}
    </Button>
  );
}
