import * as React from 'react';
const SvgIcoDocuments = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.247 8.598a2.35 2.35 0 012.35-2.35h5.54a2.35 2.35 0 011.663.689l2.263 2.262c.44.441.689 1.04.689 1.663v8.54a2.35 2.35 0 01-2.351 2.352H5.598a2.35 2.35 0 01-2.35-2.351V8.598zm2.35-.85a.85.85 0 00-.85.85v10.805c0 .47.38.85.85.85h7.804c.47 0 .85-.38.85-.85v-8.54a.851.851 0 00-.249-.603L11.74 7.997a.851.851 0 00-.602-.25h-5.54z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 4.597a2.35 2.35 0 012.35-2.35h7.403a2.75 2.75 0 012.75 2.75V15.4a2.35 2.35 0 01-2.35 2.35h-3.401a.75.75 0 110-1.5h3.401c.47 0 .85-.38.85-.85V4.997c0-.69-.56-1.25-1.25-1.25H10.6a.85.85 0 00-.85.85v2.4a.75.75 0 11-1.5 0v-2.4z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6.248a.75.75 0 01.75.75v3.201c0 .028.023.05.05.05h3.202a.75.75 0 010 1.5H11.8a1.55 1.55 0 01-1.55-1.55V6.998a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoDocuments;
