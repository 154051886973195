import { useContext } from 'react';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useSocketClient } from '../../../providers/SocketContext';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DefaultSocketResponseType } from '../../../services/socket/SocketEvents';
import { updateGridBlockState, updateGridTableBlockState } from '../grid/reduxStore/editorSlice';
import { saveContentWithSocket, saveTableContentWithSocket, TableContentType } from '../grid/reduxStore/saveHandlers';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';

export function useBlockContentChangedHandlerWithoutUndoRedo() {
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);
  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { data } = useCurrentUser();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const userId = data.id;

  const blockContentChangedCallback = (
    gridBlockContentChangedSocketResponse: DefaultSocketResponseType,
    blockId: string,
    sectionId: string,
    newContent: string
  ) => {
    updateSaveStatus(gridBlockContentChangedSocketResponse);
    checkDocumentLockStatus(gridBlockContentChangedSocketResponse.errorCode);

    const payload = {
      blockId: blockId,
      content: newContent,
      sectionId: sectionId,
    };
    dispatch(updateGridBlockState(payload));
  };

  const blockContentTableChangedCallback = (
    gridBlockContentChangedSocketResponse: DefaultSocketResponseType,
    blockId: string,
    sectionId: string,
    newContent: TableContentType
  ) => {
    updateSaveStatus(gridBlockContentChangedSocketResponse);
    checkDocumentLockStatus(gridBlockContentChangedSocketResponse.errorCode);
    const payload = {
      sectionId: sectionId,
      blockId: blockId,
      contentTable: newContent,
    };
    dispatch(updateGridTableBlockState(payload));
  };

  return async (blockId: string, sectionId: string, newContent: string | TableContentType) => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    if (typeof newContent !== 'string') {
      return dispatch(
        saveTableContentWithSocket({
          blockId: blockId,
          content: newContent,
          socketClient: socketClient,
          callback: (blockContentChangedSocketResponse) => {
            blockContentTableChangedCallback(blockContentChangedSocketResponse, blockId, sectionId, newContent);
          },
        })
      ).unwrap();
    }
    return dispatch(
      saveContentWithSocket({
        blockId: blockId,
        content: newContent,
        userId: userId,
        socketClient: socketClient,
        callback: (blockContentChangedSocketResponse) => {
          blockContentChangedCallback(blockContentChangedSocketResponse, blockId, sectionId, newContent);
        },
      })
    ).unwrap();
  };
}
