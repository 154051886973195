import * as React from 'react';
const SvgIcoClients = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5.67 10.665a.75.75 0 01-.335 1.006l-2 1a.75.75 0 11-.67-1.342l2-1a.75.75 0 011.006.336z" />
      <path d="M4 10.75a.75.75 0 01.75.75V13a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM5.67 14.665a.75.75 0 01-.335 1.006l-2 1a.75.75 0 11-.67-1.342l2-1a.75.75 0 011.006.336z" />
      <path d="M4 14.75a.75.75 0 01.75.75V17a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM5.67 6.665a.75.75 0 01-.335 1.006l-2 1a.75.75 0 01-.67-1.342l2-1a.75.75 0 011.006.336z" />
      <path d="M4 6.75a.75.75 0 01.75.75V9a.75.75 0 01-1.5 0V7.5A.75.75 0 014 6.75zM8 2.25a.75.75 0 01.75.75v18a.75.75 0 01-1.5 0V3A.75.75 0 018 2.25zM11.25 8a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM11.25 12a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z" />
      <path d="M3.25 4c0-.966.784-1.75 1.75-1.75h13A2.75 2.75 0 0120.75 5v14A2.75 2.75 0 0118 21.75H5A1.75 1.75 0 013.25 20v-.5a.75.75 0 011.5 0v.5c0 .138.112.25.25.25h13c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25H5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0V4z" />
    </g>
  </svg>
);
export default SvgIcoClients;
