import * as React from 'react';
const SvgIcoTableColumnAddBefore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 2a2 2 0 00-2 2v16a2 2 0 002 2h9V2h-9zm7 8v4h-7v-4h7zm0 6v4h-7v-4h7zm0-12v4h-7V4h7zM9 11H6V8H4v3H1v2h3v3h2v-3h3v-2z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoTableColumnAddBefore;
