import React, { useCallback, useState, useEffect, useContext } from 'react';
import { AcknowledgmentResponseStatus, ErrorCode } from 'services/socket/SocketEvents';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import {
  SignatureBox,
  SignaturePosition,
  SignatureDimensions,
  UnSignedSignatureBox,
} from 'services/repositories/interfaces/SignatureRepository';
import { SaveStatusContext } from './SaveStatusProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { SignaturePropertyUpdate } from '../../../services/socket/SocketClient';

export type SignatureSocketWriteOperationPayload = UnSignedSignatureBox;

type HandleSignatureSocketWriteOperation = (sectionId: string, params: SignatureSocketWriteOperationPayload) => void;
export type SectionsSignaturesType = { [sectionId: string]: SignatureBox[] };
type SelectedSignatureType = { sectionId: string; signatureId: string };

type HandleSignaturePositionUpdateType = (sectionId: string, signatureId: string, newPosition: SignaturePosition) => void;
type HandleSignatureDimensionUpdateType = (sectionId: string, signatureId: string, newDimension: SignatureDimensions) => void;

interface SignatureContextType {
  documentId: string;
  handleSignatureInsert: HandleSignatureSocketWriteOperation;
  handleSignatureRemoval: (selectedSignature: SelectedSignatureType) => void;
  handleSignaturePropertyUpdate: (sectionId: string, newProperties: SignaturePropertyUpdate) => void;
  handleSignaturePositionUpdate: HandleSignaturePositionUpdateType;
  handleSignatureDimensionUpdate: HandleSignatureDimensionUpdateType;
  setSignaturesOnMount: (signatures: SectionsSignaturesType) => void;
  setSelectedSignature: (selectedSignature: SelectedSignatureType) => void;
  getSignaturesBySectionId: (sectionId: string) => SignatureBox[];
}

export const SignaturesContext = React.createContext<SignatureContextType>({} as SignatureContextType);

interface SignaturesProviderProps {
  documentId: string;
  children: React.ReactNode;
}

export const SignaturesProvider = ({ documentId, children }: SignaturesProviderProps) => {
  const { socketClient } = useSocketClient();
  const [sectionsSignatures, setSectionsSignatures] = useState<SectionsSignaturesType>({});
  const [selectedSignature, setSelectedSignature] = useState<SelectedSignatureType | null>(null);
  const { setSaveStatus } = useContext(SaveStatusContext);

  const signatureActionAck = ({ status, errorCode }: { status: AcknowledgmentResponseStatus; errorCode: ErrorCode }) => {
    if (status === AcknowledgmentResponseStatus.OK) {
      setSaveStatus(DocumentSaveStatus.SAVED);
    } else {
      setSaveStatus(DocumentSaveStatus.NOT_SAVED, errorCode);
    }
  };

  const handleSignatureInsert = useCallback(
    (sectionId: string, signature: UnSignedSignatureBox) => {
      setSaveStatus(DocumentSaveStatus.SAVING);
      socketClient.addSignatureContent({ ...signature, sectionId }, (response) => {
        signatureActionAck(response);

        setSectionsSignatures((prevSectionsSignatures) => {
          const { content } = response;

          if (content) signature.signatureBoxId = content.id;
          const currentSectionSignatures = prevSectionsSignatures[sectionId] ?? [];
          return { ...prevSectionsSignatures, [sectionId]: [...currentSectionSignatures, signature] };
        });
      });
    },
    [sectionsSignatures]
  );

  const handleSignatureRemoval = useCallback(
    ({ sectionId, signatureId }: SelectedSignatureType) => {
      setSectionsSignatures((prevSectionsSignatures) => {
        return {
          ...prevSectionsSignatures,
          [sectionId]: prevSectionsSignatures[sectionId].filter((signature) => {
            if (signature.signatureBoxId === signatureId) {
              setSaveStatus(DocumentSaveStatus.SAVING);
              socketClient.deleteSignatureContent(signature, (response) => signatureActionAck(response));
            }
            return signature.signatureBoxId !== signatureId;
          }),
        };
      });
    },
    [sectionsSignatures]
  );

  const handleSignaturePositionUpdate: HandleSignaturePositionUpdateType = (sectionId, signatureId, newPosition) => {
    setSectionsSignatures((prevSignatures) => {
      prevSignatures[sectionId].map((signature) => {
        if (signature.signatureBoxId === signatureId) {
          Object.assign(signature.properties.position, newPosition);
        }
      });
      return prevSignatures;
    });
  };

  const handleSignatureDimensionUpdate: HandleSignatureDimensionUpdateType = (sectionId, signatureId, newDimension) => {
    setSectionsSignatures((prevSignatures) => {
      prevSignatures[sectionId].map((signature) => {
        if (signature.signatureBoxId === signatureId) {
          Object.assign(signature.properties.dimensions, newDimension);
        }
      });
      return prevSignatures;
    });
  };

  const handleSignaturePropertyUpdate = useCallback(
    (sectionId: string, newProperties: SignaturePropertyUpdate) => {
      setSaveStatus(DocumentSaveStatus.SAVING);
      socketClient.updateSignatureContent(newProperties, (response) => signatureActionAck(response));
      setSectionsSignatures((prevSignatures) => {
        prevSignatures[sectionId].map((signature) => {
          if (signature.signatureBoxId === newProperties.signatureBoxId) {
            Object.assign(signature, newProperties);
          }
        });
        return prevSignatures;
      });
    },
    [sectionsSignatures]
  );

  const setSignaturesOnMount = useCallback((data: SectionsSignaturesType) => {
    setSectionsSignatures(data);
  }, []);

  const handleMouseDown = useCallback(() => {
    setSelectedSignature(null);
  }, []);

  const handleHotKeys = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      if ((key === 'Delete' || key === 'Backspace') && selectedSignature) {
        handleSignatureRemoval(selectedSignature);
      }
    },
    [handleSignatureRemoval, selectedSignature]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleHotKeys);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleHotKeys);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, handleHotKeys]);

  const getSignaturesBySectionId = (sectionId: string): SignatureBox[] => {
    if (sectionsSignatures && sectionsSignatures[sectionId]) {
      return sectionsSignatures[sectionId];
    }
    return [];
  };

  return (
    <SignaturesContext.Provider
      value={{
        documentId,
        handleSignatureInsert,
        handleSignatureRemoval,
        handleSignaturePropertyUpdate,
        handleSignaturePositionUpdate,
        handleSignatureDimensionUpdate,
        setSignaturesOnMount,
        setSelectedSignature,
        getSignaturesBySectionId,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};
