import * as React from 'react';
const SvgIcoRestoreDocument = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.001 2h-8c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.89 2 1.99 2h12.01c1.1 0 2-.9 2-2V8l-6-6zm4 18h-12V4h7.17l4.83 4.83V20zm-9.55-9.43L7.281 9.4V13h3.6l-1.44-1.44a3.15 3.15 0 012.79-1.71 3.15 3.15 0 110 6.3 3.14 3.14 0 01-2.58-1.35h-1.55a4.51 4.51 0 004.12 2.7c2.48 0 4.5-2.02 4.5-4.5s-2.02-4.5-4.5-4.5c-1.59 0-2.97.83-3.77 2.07z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoRestoreDocument;
