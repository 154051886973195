import * as React from 'react';
const SvgIcoBracketAddPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.574 20.003a2.287 2.287 0 002.287-2.287V6.284a2.286 2.286 0 00-2.287-2.287M14.501 12H9.499M12 14.501V9.499M5.426 3.997A2.286 2.286 0 003.14 6.284v11.432a2.286 2.286 0 002.287 2.287"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoBracketAddPlus;
