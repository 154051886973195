import * as React from 'react';
const SvgIcoInterests = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.87 25.249a7.331 7.331 0 00-7.333 7.333 7.331 7.331 0 007.333 7.333 7.331 7.331 0 007.333-7.333 7.331 7.331 0 00-7.333-7.333zm0 11a3.677 3.677 0 01-3.667-3.667 3.677 3.677 0 013.667-3.667 3.677 3.677 0 013.667 3.667 3.677 3.677 0 01-3.667 3.667zm10.963-11v14.666H38.5V25.25H23.833zm11 11H27.5v-7.334h7.333v7.334zm-22-31.167l-9.166 16.5H22l-9.167-16.5zm0 7.553l2.934 5.28H9.9l2.933-5.28zM35.292 6c-1.944 0-3.319 1.026-4.125 2.145-.807-1.119-2.182-2.145-4.125-2.145-2.86 0-5.042 2.346-5.042 5.041 0 3.667 4.437 6.27 9.167 10.542 4.73-4.272 9.166-6.875 9.166-10.542C40.333 8.345 38.152 6 35.292 6zm-4.125 10.725c-2.659-2.237-5.5-4.4-5.5-5.684 0-.788.641-1.375 1.375-1.375.568 0 .953.312 1.338.679l2.787 2.621 2.786-2.621c.385-.367.77-.679 1.339-.679.733 0 1.375.587 1.375 1.375 0 1.284-2.842 3.447-5.5 5.684z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoInterests;
