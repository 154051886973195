import { createContext, useContext } from 'react';

type ProviderType = {
  children: React.ReactElement;
  values: Values;
};

type Values = {
  contentId: string;
};

export const EditorContentContext = createContext<Values>({} as Values);

export function useEditorContent(): Values {
  const values = useContext(EditorContentContext);
  if (values === undefined) {
    throw new Error('useEditorContent must be used within a EditorContentProvider');
  }
  return values;
}

export function EditorContentProvider({ values, children }: ProviderType) {
  return <EditorContentContext.Provider value={values}>{children}</EditorContentContext.Provider>;
}
