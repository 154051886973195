import * as React from 'react';
const SvgIcoCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.542 5.481a.75.75 0 01-.023 1.06L9.217 18.322a.75.75 0 01-1.197.22l-5.539-5.303a.75.75 0 111.038-1.083l4.984 4.772L20.481 5.458a.75.75 0 011.06.023z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoCheckmark;
