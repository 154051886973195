import Icon from 'components/icon';
import './styles.less';
interface Props {
  onDelete: () => void;
  title: string;
  label: string;
}
export function SignatureDetail({ onDelete, title, label }: Props) {
  return (
    <div className="signature_detail">
      <div className="signature_detail__content" title={title}>
        {label}
      </div>
      <div className="signature_detail__actions">
        <button className="signature_detail__action" onClick={onDelete}>
          <Icon name="IcoTrash" title="Delete" />
        </button>
      </div>
    </div>
  );
}
