import * as React from 'react';
const SvgIcoCoffeeCup = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.377 11.262h-1.425v2.525h1.422c.983 0 1.538-.42 1.538-1.284 0-.863-.551-1.241-1.535-1.241zm5.794-4.836h1.82l-1.424-2.754h-.547L17.14 2H6.849l-.88 1.672h-.545L4 6.426h1.82l.357 2.534H5.63l1.133 9.2h.713L8.02 22h7.96l.54-3.84h.707l1.132-9.2h-.541l.354-2.534zM5.088 5.73l.678-1.37h5.663l.437-.688H6.744l.487-.981h9.526l.497.981h-1.9l.437.693h2.431l.676 1.37-13.81-.005zm10.337 15.577H8.564l-.426-3.155h7.719l-.432 3.155zM9.88 16.79v-6.494h2.542c1.602 0 2.523.792 2.523 2.177 0 1.443-.92 2.287-2.523 2.287h-1.47v2.03H9.88zm7.246-7.83H6.864l-.346-2.534H17.47l-.345 2.534z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoCoffeeCup;
