import * as React from 'react';
const SvgIcoConnect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.006 7.998a.75.75 0 01.334 1.007l-1.126 2.245h2.787a.75.75 0 01.67 1.086l-1.67 3.331a.75.75 0 01-1.341-.672l1.126-2.245H9.999a.75.75 0 01-.67-1.086l1.67-3.332a.75.75 0 011.007-.334z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.746a8.254 8.254 0 100 16.508 8.254 8.254 0 000-16.508zM2.246 12c0-5.387 4.367-9.754 9.754-9.754S21.754 6.613 21.754 12 17.387 21.754 12 21.754 2.246 17.387 2.246 12z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoConnect;
