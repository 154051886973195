import * as React from 'react';
const SvgIcoSectionLibrary = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M2.25 6A2.75 2.75 0 015 3.25h4.471c.58 0 1.12.287 1.446.765l1.405 2.065c.046.068.124.11.207.11H19a2.75 2.75 0 012.75 2.75V18A2.75 2.75 0 0119 20.75H5A2.75 2.75 0 012.25 18V6zM5 4.75c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V8.94c0-.69-.56-1.25-1.25-1.25h-6.471a1.75 1.75 0 01-1.447-.767L9.677 4.86a.25.25 0 00-.206-.109H5z" />
      <path d="M7 11.25a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5a.75.75 0 01-.75-.75zM7 15.25a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5a.75.75 0 01-.75-.75z" />
    </g>
  </svg>
);
export default SvgIcoSectionLibrary;
