import * as React from 'react';
const SvgIcoTextbox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M6.248 4.997a.75.75 0 01.75-.75h10.004a.75.75 0 110 1.5H6.998a.75.75 0 01-.75-.75zM12 9.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75z" />
      <path d="M4.997 3.746a1.25 1.25 0 100 2.502 1.25 1.25 0 000-2.502zm0 4.002a2.75 2.75 0 110-5.502 2.75 2.75 0 010 5.502z" />
      <path d="M4.997 6.248a.75.75 0 01.75.75v10.004a.75.75 0 11-1.5 0V6.998a.75.75 0 01.75-.75zM9.25 10a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM19.003 6.248a.75.75 0 01.75.75v10.004a.75.75 0 11-1.5 0V6.998a.75.75 0 01.75-.75z" />
      <path d="M19.003 3.746a1.25 1.25 0 100 2.502 1.25 1.25 0 000-2.502zm0 4.002a2.75 2.75 0 110-5.502 2.75 2.75 0 010 5.502zM19.003 17.752a1.251 1.251 0 100 2.502 1.251 1.251 0 000-2.502zm-2.751 1.25a2.75 2.75 0 115.502 0 2.75 2.75 0 01-5.502 0z" />
      <path d="M6.248 19.003a.75.75 0 01.75-.75h10.004a.75.75 0 110 1.5H6.998a.75.75 0 01-.75-.75z" />
      <path d="M4.997 17.752a1.25 1.25 0 100 2.502 1.25 1.25 0 000-2.502zm-2.75 1.25a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z" />
    </g>
  </svg>
);
export default SvgIcoTextbox;
