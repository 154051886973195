import * as React from 'react';
const SvgIcoSettingsLibrary = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 5A2.756 2.756 0 016 2.25h13c.47 0 .92.16 1.255.495.335.334.495.785.495 1.255v8.8a.75.75 0 01-1.5 0V4c0-.13-.04-.18-.055-.195-.016-.015-.065-.055-.195-.055H6c-.686 0-1.25.564-1.25 1.25v12a.75.75 0 01-1.5 0V5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.25a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0V3A.75.75 0 018 2.25z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 17A2.756 2.756 0 016 14.25h7.3a.75.75 0 010 1.5H6c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25h7.3a.75.75 0 010 1.5H6A2.756 2.756 0 013.25 17zM18.748 14.25a.75.75 0 01.75.75v1.042a.75.75 0 01-1.5 0V15a.75.75 0 01.75-.75zM18.748 19.43a.75.75 0 01.75.75v1.042a.75.75 0 01-1.5 0V20.18a.75.75 0 01.75-.75zM22.09 16.184a.75.75 0 01-.28 1.023l-.902.514a.75.75 0 11-.742-1.304l.902-.514a.75.75 0 011.023.28zM17.592 18.776a.75.75 0 01-.273 1.025l-.886.514a.75.75 0 01-.752-1.298l.886-.514a.75.75 0 011.025.273zM19.885 18.782a.75.75 0 011.023-.28l.902.513a.75.75 0 11-.742 1.303l-.902-.514a.75.75 0 01-.28-1.022zM15.408 16.18a.75.75 0 011.025-.273l.886.514a.75.75 0 11-.752 1.298l-.886-.514a.75.75 0 01-.273-1.025z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.748 16.786a1.325 1.325 0 100 2.65 1.325 1.325 0 000-2.65zm0-1.5a2.825 2.825 0 100 5.65 2.825 2.825 0 000-5.65z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoSettingsLibrary;
