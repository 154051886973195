import * as React from 'react';
const SvgIcoLink = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.178 4.074a5.022 5.022 0 117.1 7.1l-1.726 1.728a.75.75 0 01-1.06-1.061l1.738-1.739a3.521 3.521 0 10-4.98-4.98l-.008.008-3.702 3.702a3.52 3.52 0 000 4.98.75.75 0 01.085.1c.26.236.554.43.872.578a.75.75 0 11-.629 1.361 4.966 4.966 0 01-1.394-.963.75.75 0 01-.108-.134 5.02 5.02 0 01.114-6.983l3.698-3.697z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.126 9.229a.75.75 0 01.995-.366c.517.24.988.566 1.394.964l.005.005a5.02 5.02 0 010 7.101l-3.71 3.71a5.022 5.022 0 01-7.1-7.101l.007-.008 1.731-1.731a.75.75 0 011.06 1.06L4.783 14.59a3.522 3.522 0 004.98 4.98l3.697-3.697a3.52 3.52 0 00.003-4.978 3.47 3.47 0 00-.97-.67.75.75 0 01-.366-.996z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoLink;
