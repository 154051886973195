import * as React from 'react';
const SvgIcoDisconnect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 12a.75.75 0 01.75-.75h8a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75A8.25 8.25 0 003.75 12 8.25 8.25 0 0012 20.25 8.25 8.25 0 0020.25 12 8.25 8.25 0 0012 3.75zM2.25 12A9.75 9.75 0 0112 2.25 9.75 9.75 0 0121.75 12 9.75 9.75 0 0112 21.75 9.75 9.75 0 012.25 12z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoDisconnect;
