import { Input } from 'antd';
import { Heading } from 'components/typography';
import { Form } from 'components/form';
import { VariableEntity, VariablesGroup } from 'services/entities/VariablesEntity';
import { UsedVariable } from './interfaces';
import { useTranslation } from 'react-i18next';
import './styles.less';
import Icon from 'components/icon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../grid/reduxStore/Store';

const getVariableItems = (variables: VariableEntity[]) => {
  return variables.map((variable: VariableEntity) => {
    return (
      <Form.Item key={variable?.name} label={variable?.friendlyName}>
        <Input value={variable?.value as string} readOnly></Input>
      </Form.Item>
    );
  });
};

const formatUsedVariables = (usedVariables: UsedVariable[], variables: VariablesGroup | null): VariableEntity[] => {
  const formattedUsedVariables: VariableEntity[] = [];
  let foundVariable: VariableEntity | undefined;

  if (!variables) return formattedUsedVariables;

  for (const usedVariable of usedVariables) {
    if (!usedVariable.type) continue;

    foundVariable = variables[usedVariable.type].find((variable) => variable.name === usedVariable.name);

    if (!foundVariable) continue;

    formattedUsedVariables.push(foundVariable);
  }

  return formattedUsedVariables;
};

const VariablesForm = () => {
  const allUsedVariables = useSelector((state: RootState) => {
    return state.usedVariablesReducer.usedVariables;
  });
  const allVariables = useSelector((state: RootState) => {
    return state.allVariables.data;
  });

  const [uniqueUsedVariables, setUniqueUsedVariables] = useState<UsedVariable[]>([]);
  const formattedUsedVariables = formatUsedVariables(uniqueUsedVariables, allVariables);
  const { t } = useTranslation();

  useEffect(() => {
    const uniqueNames = new Set<string>();
    const mergedArray: UsedVariable[] = [];
    Object.values(allUsedVariables).forEach((usedVariable) => {
      usedVariable.forEach((variable) => {
        if (!uniqueNames.has(variable.name)) {
          uniqueNames.add(variable.name);
          mergedArray.push(variable);
        }
      });
    });

    setUniqueUsedVariables(mergedArray);
  }, [allUsedVariables]);

  return (
    <Form layout={'vertical'} className="variables__sidepanel">
      {formattedUsedVariables.length > 0 && (
        <>
          <Heading level={5}>{t('editor.variables.used')}</Heading>
          <div data-testid="used-variables">{getVariableItems(formattedUsedVariables)}</div>
        </>
      )}
      <Heading level={5}>{t('editor.variables.user')}</Heading>
      <div data-testid="user-variables">{getVariableItems(allVariables?.user ?? [])}</div>
      <Heading level={5}>{t('editor.variables.document')}</Heading>
      <div data-testid="document-variables">{getVariableItems(allVariables?.proposal ?? [])}</div>
      <Heading level={5}>{t('editor.variables.client')}</Heading>
      <div data-testid="client-variables">{getVariableItems(allVariables?.client ?? [])}</div>
    </Form>
  );
};

export function Variables() {
  const { t } = useTranslation();
  return (
    <div className="variables__sidepanel-container">
      <div className="variables__sidepanel-helper">
        <Heading level={5}>Variables</Heading>
        <Icon name={'IcoBracketAddPlus'} />
        <Heading level={3}>Add a Variable</Heading>
        <p className="micro-one">{t('editor.variables.helper_text')}</p>
      </div>
      <VariablesForm />
    </div>
  );
}
