import { Heading, Paragraph } from 'components';
import { useTranslation } from 'react-i18next';
import './styles.less';
import { Button } from 'components';
import { Dispatch, SetStateAction } from 'react';
import TravoltaImage from '../../../../images/documents-page/travolta-confused.jpg';
import { Image } from 'antd';

export type Props = {
  setSelectedItemStatus: Dispatch<SetStateAction<string[]>>;
  handleStatusFilter: (value: string) => void;
};

export function EmptyState({ setSelectedItemStatus, handleStatusFilter }: Props) {
  const { t } = useTranslation();

  const handleAllDocumentsButtonClick = () => {
    setSelectedItemStatus(['All']);
    handleStatusFilter('');
  };

  return (
    <div className="pipeline__empty_state_container">
      <div className="pipeline__empty_state_content">
        <Image src={TravoltaImage} />
        <Heading level={3}>{t('pipeline.empty_state.title')}</Heading>
        <Paragraph size="md">{t('pipeline.empty_state.content_msg_1')}</Paragraph>
        <Paragraph size="md">{t('pipeline.empty_state.content_msg_2')}</Paragraph>
        <Button
          className="all-documents-button"
          type="primary"
          variant="positive"
          onClick={handleAllDocumentsButtonClick}
          data-testid="all-documents-button"
        >
          {t('pipeline.all_documents_button')}
        </Button>
      </div>
    </div>
  );
}
