import { accessToken } from 'services/authorization/handleTokenCookie';
import DocumentBuilder from 'components/editor/DocumentBuilder/DocumentBuilder';
import { SignaturesProvider } from 'components/editor/providers/SignaturesProvider';
import { Provider } from 'react-redux';
import { rootStore } from '../../components/editor/grid/reduxStore/Store';
import { SocketProvider } from '../../providers/SocketContext';
import { SaveStatusProvider } from 'components/editor/providers/SaveStatusProvider';
import { SelectedBlockInfoProvider } from '../../components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { BlockSettingsProvider } from '../../components/editor/SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import { EditorContentProvider } from 'providers/EditorContentContext';
import { DocumentLockedModalProvider } from 'components/editor/modals/DocumentLockedModalProvider';
interface Props {
  id: string;
  contentId: string;
  isDocumentLocked: boolean | null;
}

export function EditorContentWrapper({ id, contentId, isDocumentLocked }: Props) {
  return (
    <SocketProvider accessToken={accessToken as string} contentId={contentId}>
      <Provider store={rootStore}>
        <SaveStatusProvider>
          <DocumentLockedModalProvider documentId={id} isDocumentLocked={isDocumentLocked}>
            <SignaturesProvider documentId={contentId}>
              <SelectedBlockInfoProvider>
                <BlockSettingsProvider>
                  <EditorContentProvider values={{ contentId: contentId }}>
                    <DocumentBuilder documentId={id} />
                  </EditorContentProvider>
                </BlockSettingsProvider>
              </SelectedBlockInfoProvider>
            </SignaturesProvider>
          </DocumentLockedModalProvider>
        </SaveStatusProvider>
      </Provider>
    </SocketProvider>
  );
}
