import { useContext } from 'react';
import { saveConfigWithSocket } from '../grid/reduxStore/saveHandlers';
import { updateGridPositionConfig } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';
import { UndoRedoCommandForBlocks } from '../UndoRedo/UndoRedoCommand';
import { BlockEvents, DefaultSocketResponseType } from '../../../services/socket/SocketEvents';

type PositionStateType = {
  xAxisPx: number;
  yAxisPx: number;
};

export type BlockPositionChangedHandlerWithoutUndoRedoType = (
  sectionId: string,
  blockId: string,
  blockState: PositionStateType
) => Promise<void>;

const useUndoRedoPositionChangeHandler = (blockPositionChangedHandlerWithoutUndoRedo: BlockPositionChangedHandlerWithoutUndoRedoType) => {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();

  return (sectionId: string, blockId: string, positionBefore: PositionStateType, positionAfter: PositionStateType) => {
    const undoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        await blockPositionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, positionBefore);
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        await blockPositionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, positionAfter);
      } catch (error) {
        return false;
      }
      return true;
    };
    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommandForBlocks(blockId, undoCallback, redoCallback));
  };
};

export function useBlockPositionChangedHandlerWithoutUndoRedo(): BlockPositionChangedHandlerWithoutUndoRedoType {
  const dispatch = useAppDispatch();
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);
  const { data } = useCurrentUser();
  const userId = data.id;
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const { socketClient } = useSocketClient();
  const blockPositionChangedCallback = (blockPositionChangedSocketResponse: DefaultSocketResponseType) => {
    updateSaveStatus(blockPositionChangedSocketResponse);
    checkDocumentLockStatus(blockPositionChangedSocketResponse.errorCode);
  };

  return async (sectionId: string, blockId: string, blockState: PositionStateType): Promise<void> => {
    const payload = {
      sectionId,
      blockId,
      x: blockState.xAxisPx,
      y: blockState.yAxisPx,
    };
    setSaveStatus(DocumentSaveStatus.SAVING);
    dispatch(updateGridPositionConfig(payload));
    return await dispatch(
      saveConfigWithSocket({
        sectionId,
        blockId,
        x: blockState.xAxisPx,
        y: blockState.yAxisPx,
        userId: userId,
        eventType: BlockEvents.BLOCK_POSITION_CHANGED,
        socketClient,
        callback: blockPositionChangedCallback,
      })
    ).unwrap();
  };
}

export function useBlockPositionChangedHandler() {
  const blockPositionChangedHandlerWithoutUndoRedo = useBlockPositionChangedHandlerWithoutUndoRedo();
  const undoRedoPositionChangeHandler = useUndoRedoPositionChangeHandler(blockPositionChangedHandlerWithoutUndoRedo);
  const { setSaveStatus } = useContext(SaveStatusContext);

  return async (sectionId: string, blockId: string, positionBefore: PositionStateType, positionAfter: PositionStateType): Promise<void> => {
    setSaveStatus(DocumentSaveStatus.SAVING);

    const blockPositionChangedSocketResponse = await blockPositionChangedHandlerWithoutUndoRedo(sectionId, blockId, positionAfter);

    undoRedoPositionChangeHandler(sectionId, blockId, positionBefore, positionAfter);
    return blockPositionChangedSocketResponse;
  };
}
