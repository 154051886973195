import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ChangeEvent, useContext, useRef } from 'react';
import './ImageUploadButton.less';
import { editorConfig } from 'components/editor/helpers/config';
import { useDocumentImageUploadMutation } from 'hooks/useDocumentImageMutation';
import { useTranslation } from 'react-i18next';
import { openNotification } from 'components/notification';
import { DocumentImageLibraryPayload, UploadDocumentImageResponse } from 'services/repositories/interfaces/DocumentRepository';
import { ImageUploadErrorResponse } from 'components/editor/shared/models/ImageUploadErrorResponse';
import { getImageStatusError } from 'components/editor/helpers/getImageStatusError';
import { PromiseAllSettledResult, PromiseAllSettledStatus } from 'interfaces/PromisedAllSettledResult';
import { CheckImageExtension } from 'components/editor/helpers/CheckImageExtension';
import { SaveStatusContext } from 'components/editor/providers/SaveStatusProvider';
import { DocumentSaveStatus } from 'components/editor/shared/models/DocumentSaveStatus';
import { EditorContentContext } from 'providers/EditorContentContext';

export default function ImageUploadButton({ refreshImages }: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useDocumentImageUploadMutation();
  const { t } = useTranslation();
  const { setSaveStatus } = useContext(SaveStatusContext);
  const { contentId } = useContext(EditorContentContext);

  const uploadFile = (files: File[]) => {
    const payload: DocumentImageLibraryPayload = {
      documentId: contentId,
      images: files,
    };

    uploadImage(payload, {
      onSuccess: async (result: PromiseAllSettledResult<UploadDocumentImageResponse>[]) => {
        const hasSuccess = result.some((r) => r.status === PromiseAllSettledStatus.Fulfilled);
        const errors = result.filter((r) => r.status === PromiseAllSettledStatus.Rejected);

        if (errors.length && hasSuccess) {
          setSaveStatus(DocumentSaveStatus.SAVED);
          openNotification({
            type: 'warning',
            title: t('document.image.errors.mixed_success_title'),
            description: t('document.image.errors.mixed_success_desc'),
          });
        } else if (hasSuccess) {
          setSaveStatus(DocumentSaveStatus.SAVED);
          openNotification({
            type: 'success',
            title: t('document.image.success'),
            description: '',
          });
        } else {
          setSaveStatus(DocumentSaveStatus.NOT_SAVED);
          if (errors.length === 1) {
            const { title, desc } = getImageStatusError(errors?.[0]?.reason?.status_code, t);

            openNotification({
              title,
              description: desc,
              type: 'error',
            });
          } else {
            const errorMessages = new Set<string>();

            errors.forEach((e) => {
              const { desc } = getImageStatusError(e?.reason?.status_code, t);

              errorMessages.add(desc);
            });

            openNotification({
              type: 'error',
              title: t('document.image.errors.multiple_errors'),
              description: Array.from(errorMessages).join(', '),
            });
          }
        }

        refreshImages();
      },
      onError: (error) => {
        if (!error) {
          return;
        }

        setSaveStatus(DocumentSaveStatus.NOT_SAVED);
        const e = error as ImageUploadErrorResponse;
        const { title, desc } = getImageStatusError(e.status_code, t);

        openNotification({
          title,
          description: desc,
          type: 'error',
        });
      },
    });
  };

  const handleClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;

    if (!files?.length) {
      return;
    }

    for (const file of Array.from(files)) {
      if (!CheckImageExtension(file)) {
        openNotification({
          title: t('document.image.errors.not_supported_types_title'),
          description: t('document.image.errors.not_supported_types_desc'),
          type: 'error',
        });

        return;
      }
    }
    setSaveStatus(DocumentSaveStatus.SAVING);
    uploadFile(Array.from(files));
    event.target.value = '';
  };

  return (
    <>
      <div className="upload-button-container" onClick={handleClick}>
        <AddCircleOutlineIcon className="upload-button-icon" />
      </div>
      <input type="file" onChange={handleChange} ref={fileInputRef} accept={editorConfig.acceptedImageTypes.join(',')} hidden multiple />
    </>
  );
}
