import * as React from 'react';
const SvgIcoClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.196 8.137a.468.468 0 010 .662l-7.063 7.064a.468.468 0 01-.663-.662l7.064-7.064a.468.468 0 01.662 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47 8.137a.468.468 0 01.663 0l7.063 7.064a.468.468 0 01-.662.662L8.47 8.799a.468.468 0 010-.662z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M16.196 8.137a.468.468 0 010 .662l-7.063 7.064a.468.468 0 01-.663-.662l7.064-7.064a.468.468 0 01.662 0z"
      stroke="currentColor"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M8.47 8.137a.468.468 0 01.663 0l7.063 7.064a.468.468 0 01-.662.662L8.47 8.799a.468.468 0 010-.662z"
      stroke="currentColor"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoClose;
