import * as React from 'react';
const SvgIcoTemplates = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5 3.75c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25H5zM2.25 5A2.75 2.75 0 015 2.25h14A2.75 2.75 0 0121.75 5v14A2.75 2.75 0 0119 21.75H5A2.75 2.75 0 012.25 19V5z" />
      <path d="M2.25 9A.75.75 0 013 8.25h18a.75.75 0 010 1.5H3A.75.75 0 012.25 9z" />
      <path d="M10 8.25a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z" />
      <path d="M9.25 14.5a.75.75 0 01.75-.75h11a.75.75 0 010 1.5H10a.75.75 0 01-.75-.75z" />
    </g>
  </svg>
);
export default SvgIcoTemplates;
