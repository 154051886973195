import * as React from 'react';
const SvgIcoTable = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19.5 3h-15c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3h-15V5h15zm-5 14h-5v-9h5v9zm-10-9h3v9h-3v-9zm12 9v-9h3v9h-3z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoTable;
