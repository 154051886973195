import React, { useState, useRef, KeyboardEvent, useContext } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProfileActions, HelpButton, Paragraph, Button, Icon, Tooltip } from 'components';
import PriceInput from 'components/priceInput';
import { Endpoints } from 'utils/endpoints';
import useUpdateVariables from 'hooks/useUpdateVariables';
import { DocumentShareModal } from 'components/editor/document-share-modal';
import { Header } from 'components/editor/document-share-modal/header';
import { DocumentShare } from 'components/editor/document-share';
import { DocumentStatus, UpdateDocumentPayloadByContentId } from 'services/repositories/interfaces/DocumentRepository';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import './styles.less';
import SaveStatusText from '../shared/components/SaveStatus/SaveStatus';
import { EditorToolbar } from './EditorToolbar';
import { updateVariableValue } from '../grid/reduxStore/allVariableSlice';
import { useAppDispatch, RootState } from '../grid/reduxStore/Store';
import { EditorContentContext } from 'providers/EditorContentContext';
import { VariableType, VariableName } from 'components/editor/SidePanel/Variables/interfaces';
interface EditorMenuProps {
  documentId: string;
}

export const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

const findVariableByName = (variables, name: string) => {
  if (!variables) return;
  return variables.find((variable) => variable.name === name).value;
};

export function EditorMenu({ documentId }: EditorMenuProps) {
  const allVariables = useSelector((state: RootState) => state.allVariables.data);
  const contentName = findVariableByName(allVariables?.proposal, VariableName.ProposalName);
  const contentPrice = findVariableByName(allVariables?.proposal, VariableName.Total);
  const { contentId } = useContext(EditorContentContext);
  const previewURL = Endpoints.getDocumentPreviewPage(documentId);
  const handlePreviewRedirect = () => window.open(previewURL, '_blank');
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateVariables();
  const { t } = useTranslation();
  const { data: documentData } = useGetDocumentByIdQuery(documentId);
  const dispatch = useAppDispatch();

  const handleOnChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = sanitizeTitle(event.target.value);
    updateDocumentProperties({ title: sanitizedValue }, () => {
      dispatch(updateVariableValue({ type: VariableType.Proposal, variableName: VariableName.ProposalName, newValue: sanitizedValue }));
    });
  };
  const updateDocumentProperties = (payload: Omit<UpdateDocumentPayloadByContentId, 'contentId'>, onSuccess?: () => void) => {
    mutate(
      { contentId, ...payload },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess();
          }
        },
      }
    );
  };

  const handleOnChangeTotal = (value: number | null) => {
    if (value) {
      updateDocumentProperties({ price: value }, () => {
        dispatch(updateVariableValue({ type: VariableType.Proposal, variableName: VariableName.Total, newValue: value }));
      });
    }
  };

  const handleOnBlurTitle = () => {
    updateDocumentProperties({ title: contentName });
  };

  const handleOnBlurTotal = () => {
    updateDocumentProperties({ price: contentPrice });
  };

  const [isVisible, setIsVisible] = useState(false);

  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);

  const documentTitleWithCompanyName = [contentName, documentData?.company?.name].filter(Boolean).join(' - ') || contentName;

  return (
    <Layout.Header className="editor-header">
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          <Tooltip title={contentName} placement="bottom">
            <input
              ref={documentTitleRef}
              data-testid="document-title"
              onKeyDown={blurOnEnter}
              className="document-title"
              maxLength={100}
              placeholder={t('editor.default_document_title')}
              onChange={handleOnChangeTitle}
              onBlur={handleOnBlurTitle}
              value={contentName}
            />
          </Tooltip>
          <div className="document-status">
            <Paragraph size="sm">{documentData?.status || DocumentStatus.Draft}</Paragraph>
            $<PriceInput value={contentPrice} onChange={handleOnChangeTotal} onBlur={handleOnBlurTotal} />
            <Paragraph size="sm" className="timestamp-ml-xs">
              <SaveStatusText></SaveStatusText>
            </Paragraph>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          <div className="buttons-container">
            <Button
              icon={<Icon name="IcoPreview" title={t('preview')} />}
              variant="neutral"
              type="default"
              className="preview-button"
              onClick={handlePreviewRedirect}
            >
              {t('preview')}
            </Button>
            <Button
              icon={<Icon name="IcoSend" title={t('share_document')} />}
              variant="positive"
              type="primary"
              className="send-button"
              onClick={openShareModal}
            >
              {t('share_document')}
            </Button>
            <DocumentShareModal
              open={isVisible}
              onCancel={closeShareModal}
              title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
              destroyOnClose
            >
              <DocumentShare documentId={documentId} onSuccess={closeShareModal} />
            </DocumentShareModal>
          </div>
          <HelpButton />
          <ProfileActions />
        </Col>
      </Row>
      <EditorToolbar />
    </Layout.Header>
  );
}
