import { Block } from '../GridDndEditor/models/Block.model';
import { addGridBlockWithSocket, addGridTableBlockWithSocket, GridAddedSocketResponseType } from '../grid/reduxStore/saveHandlers';
import type { TableContentType } from '../grid/reduxStore/saveHandlers';
import { GridBlockType } from '../shared/gridBlockType';
import { addGridBlockState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { useContext } from 'react';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { useGridBlockDeletedHandlerWithoutUndoRedo } from './UseBlockDeletedHandler';
import { UndoRedoCommandForBlocks } from '../UndoRedo/UndoRedoCommand';
import { AcknowledgmentResponseStatus } from '../../../services/socket/SocketEvents';

type BlockAddedHandlerType = (
  sectionId: string,
  documentContent: string | TableContentType,
  blockConfig: Block,
  blockType: GridBlockType
) => Promise<GridAddedSocketResponseType>;

function useUndoRedoBlockAddedHandler(blockAddHandlerWithoutUndoRedo: BlockAddedHandlerType) {
  const gridBlockDeletedHandlerWithoutUndoRedo = useGridBlockDeletedHandlerWithoutUndoRedo();

  return (sectionId: string, blockId: string, documentContent: string | TableContentType, blockConfig: Block, blockType: GridBlockType) => {
    const undoRedoManager = UndoRedoManager.getUndoRedoManager();
    const undoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        await gridBlockDeletedHandlerWithoutUndoRedo(sectionId, mappedBlockId);
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const gridAddedSocketResponse = await blockAddHandlerWithoutUndoRedo(sectionId, documentContent, blockConfig, blockType);
        const newBlockId = gridAddedSocketResponse.content.blockId;
        undoRedoCommand.patchBlockId(newBlockId);
      } catch (error) {
        return false;
      }
      return true;
    };

    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommandForBlocks(blockId, undoCallback, redoCallback));
  };
}

export function useBlockAddedHandlerWithoutUndoRedo(): BlockAddedHandlerType {
  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { data } = useCurrentUser();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const userId = data.id;
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);

  const gridBlockAddedCallbackHandler = (
    sectionId: string,
    gridAddedSocketResponse: GridAddedSocketResponseType,
    documentContent: string | TableContentType,
    blockConfig: Block,
    blockType: GridBlockType
  ) => {
    updateSaveStatus(gridAddedSocketResponse);
    if (checkDocumentLockStatus(gridAddedSocketResponse.errorCode)) return;

    const blockId = gridAddedSocketResponse.content.blockId;
    const updatedConfig = {
      ...blockConfig,
      id: blockId,
    };
    const payload = {
      content: Array.isArray(documentContent) ? '' : documentContent,
      blockConfig: updatedConfig,
      blockId: blockId,
      blockType: blockType,
      sectionId: sectionId,
    };

    dispatch(addGridBlockState(payload));
  };

  return async (sectionId, documentContent, blockConfig, blockType) => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    const baseSocketSettings = {
      userId,
      blockConfig,
      socketClient,
      type: blockType,
      sectionId: sectionId,
      callback: (gridAddedSocketResponse: GridAddedSocketResponseType) => {
        gridBlockAddedCallbackHandler(sectionId, gridAddedSocketResponse, documentContent, blockConfig, blockType);
      },
    };

    if (typeof documentContent !== 'string') {
      return dispatch(
        addGridTableBlockWithSocket({
          ...baseSocketSettings,
          content: documentContent,
        })
      ).unwrap();
    }
    return dispatch(
      addGridBlockWithSocket({
        ...baseSocketSettings,
        content: documentContent,
      })
    ).unwrap();
  };
}

export function useBlockAddedHandler(): BlockAddedHandlerType {
  const blockAddedHandlerWithoutUndoRedo = useBlockAddedHandlerWithoutUndoRedo();
  const undoRedoBlockAddedHandler = useUndoRedoBlockAddedHandler(blockAddedHandlerWithoutUndoRedo);
  return async (sectionId, documentContent, blockConfig, blockType) => {
    const gridAddedSocketResponse = await blockAddedHandlerWithoutUndoRedo(sectionId, documentContent, blockConfig, blockType);
    if (gridAddedSocketResponse.status === AcknowledgmentResponseStatus.OK) {
      if (typeof documentContent === 'string') {
        undoRedoBlockAddedHandler(sectionId, gridAddedSocketResponse.content.blockId, documentContent, blockConfig, blockType);
      }
    }
    return gridAddedSocketResponse;
  };
}
