import * as React from 'react';
const SvgIcoDuplicate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" clipPath="url(#ico-duplicate_svg__clip0_2541_24902)">
      <path d="M11 14.8H8.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H11c.4 0 .8.3.8.8s-.4.8-.8.8z" />
      <path d="M11 17.2c-.4 0-.8-.3-.8-.8V14c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0 .4-.4.7-.8.7z" />
      <path d="M8 17.8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l3-3c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-3 3c-.2.1-.4.2-.6.2z" />
      <path d="M18 17.8h-4c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h4c.7 0 1.2-.6 1.2-1.2V7c0-.4-.1-.7-.4-1L17 4.2c-.3-.3-.6-.4-1-.4h-6c-.7 0-1.2.5-1.2 1.2v6c0 .4-.3.8-.8.8s-.8-.4-.8-.8V5c0-1.5 1.2-2.8 2.8-2.8h6c.8 0 1.5.3 2.1.9l1.8 1.8c.6.6.9 1.3.9 2.1v8c0 1.5-1.3 2.8-2.8 2.8z" />
      <path d="M20 8.2h-3c-1.2 0-2.2-1-2.2-2.2V3c0-.4.3-.8.8-.8s.8.3.8.8v3c0 .4.3.8.8.8h3c.4 0 .8.3.8.8s-.6.6-1 .6zM15 21.8H6c-.7 0-1.4-.3-1.9-.8s-.9-1.3-.9-2V8c0-.7.3-1.4.8-1.9.6-.6 1.3-.9 2-.9h2c.4 0 .8.3.8.8s-.4.8-.8.8H6c-.3 0-.6.1-.9.4-.2.2-.3.5-.3.8v11c0 .3.1.6.4.9.2.2.6.4.9.4h9c.3 0 .7-.1.9-.4.2-.3.4-.6.4-.9v-2c0-.4.3-.8.8-.8s.8.3.8.8v2c0 .7-.3 1.4-.8 1.9s-1.5.9-2.2.9z" />
    </g>
    <defs>
      <clipPath id="ico-duplicate_svg__clip0_2541_24902">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoDuplicate;
